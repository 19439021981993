import SessionManager from "../../Authentication/SessionManager/session_manager";
import { API_KEY } from "./config";

class HistorianScript {
  static getHistorianData(machine_number, start_date, end_date) {
    const company_key = this.getCompanyKey();
    const database_key = SessionManager.decryptDatabase();

    const url =
      `https://datafan-website-dashboards.azurewebsites.net/api/datafan_website_data_api?` +
      `code=${encodeURIComponent(API_KEY)}&` +
      `api_key=${encodeURIComponent(company_key)}&` +
      `database_id=${encodeURIComponent(database_key)}&` +
      `machine_number=${encodeURIComponent(machine_number)}&` +
      `type=${encodeURIComponent("historian")}&` +
      `date_start=${encodeURIComponent(start_date)}&` +
      `date_end=${encodeURIComponent(end_date)}`;

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          console.log(401);
        }
        return response.json();
      })
      .catch((error) => {
        // Handle errors here
        console.error(error); // re-throw the error to propagate it to the next catch block
      });
  }

  static getCompanyKey() {
    const company_key_encrypted = localStorage.getItem("company_key");
    return SessionManager.getCompanyApiKey(company_key_encrypted);
  }

  static getRangesAndTicks(limits) {
    const yAxisData = {};

    // Iterate over the keys of the limits object
    for (const limitKey of Object.keys(limits)) {
      const sanitizedLimitKey = limitKey.substring(3);

      // Use the upper limit for max and the lower limit for min
      const lowerLimit = parseFloat(limits[`min${sanitizedLimitKey}`]);
      const upperLimit = parseFloat(limits[`max${sanitizedLimitKey}`]);

      // Add padding (5% of the range) to the upper and lower limits
      const padding = (upperLimit - lowerLimit) * 0.25;
      const lowerRange = lowerLimit - padding;
      const upperRange = upperLimit + padding;

      // Calculate tick interval between the lower and upper limits
      const tickInterval = (upperRange - lowerRange) / 10; // For example, divide the range into 10 equal parts

      // Generate an array of ticks between lower and upper limits
      const ticks = [];
      for (let i = lowerRange; i <= upperRange; i += tickInterval) {
        ticks.push(i);
      }

      // Round the ticks to one decimal place
      const roundedTicks = ticks.map((tick) => Math.round(tick * 10) / 10);

      // Store yAxis data for current limitKey
      yAxisData[`${limitKey}_upper_axis_range`] = upperRange;
      yAxisData[`${limitKey}_lower_axis_range`] = lowerRange;
      yAxisData[`${limitKey}_ticks`] = roundedTicks;
    }

    return yAxisData;
  }

  static applyFilters(startDate, endDate) {
    console.log(startDate, endDate);
  }
}

export default HistorianScript;
