import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./historian.css"; // Import CSS file for styling
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from "recharts";
import { useParams } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HomeIcon from "@material-ui/icons/Home";

// Import the SVG file
import pressureSVG from "../../../assets/icons/druk.svg";
import tempSVG from "../../../assets/icons/temperatuur.svg";
import soundSVG from "../../../assets/icons/geluid.svg";
import humidSVG from "../../../assets/icons/vochtigheid.svg";
import vibrationSVG from "../../../assets/icons/trilling.svg";
import energySVG from "../../../assets/icons/energie.svg";
// import emergencySVG from "../../../assets/icons/emergency.svg";

// Import Datafan Logo
import datafanImage from "../../../assets/images/datafan/2023-12-Logo-Datafan.png";

// Import scripts
import HistorianScript from "../Scripts/historian";
import GetData from "../Scripts/get_card_data";
import Uitgevoerd from "../Scripts/tasks";

const graphWidth = "100%";
const graphHeight = 150;

const Historian = () => {
  const [historianData, setHistorianData] = useState(null);
  const [machineLimits, setMachineLimits] = useState(null);
  const [rangeAndTicksList, setRangeAndTicks] = useState(null);
  const [averageVibration, setAverageVibration] = useState([0, 0, 0]);
  const [dataStartDate, setDataStartDate] = useState("2024-09-06");
  const [dataEndDate, setDataEndDate] = useState("2024-09-13");
  const [lastDataUpdate, setLastDataUpdate] = useState("Loading...");
  const [buttonClicked, setButtonClicked] = useState(true);
  const totalMachineCount = 20;
  let machine_number = useParams().historian_identifier;
  let machineNum = parseInt(machine_number, 10);
  let prev_machine = machineNum - 1 === 0 ? totalMachineCount : machineNum - 1;
  let next_machine = machineNum + 1 === totalMachineCount + 1 ? 1 : machineNum + 1;

  useEffect(() => {
    if (buttonClicked) {
      fetchData();
      setButtonClicked(false);
    }

    // auto refresh every 30 minutes (1 minute for demo)
    const intervalId = setInterval(() => {
      fetchData();
    }, 30 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [machine_number, dataStartDate, dataEndDate]);

  const fetchData = async () => {
    try {
      const fetchedData = await HistorianScript.getHistorianData(
        machine_number,
        dataStartDate,
        dataEndDate
      );
      const limits = await GetData.GetLimits(machine_number);
      const rangeandticks = HistorianScript.getRangesAndTicks(limits);

      // Initialize variables to store sum and count
      let sumX = 0;
      let sumY = 0;
      let sumZ = 0;

      // Iterate over the historianData array
      fetchedData.forEach((data) => {
        // Add up the values for each property
        sumX += data.avg_x;
        sumY += data.avg_y;
        sumZ += data.avg_z;
      });

      // Calculate the averages
      const avgX = Math.round((sumX / fetchedData.length) * 10) / 10;
      const avgY = Math.round((sumY / fetchedData.length) * 10) / 10;
      const avgZ = Math.round((sumZ / fetchedData.length) * 10) / 10;

      // Extract start and end dates
      const startDate = fetchedData.length > 0 ? fetchedData[0].interval_start : "";
      const endDate =
        fetchedData.length > 0 ? fetchedData[fetchedData.length - 1].interval_start : "";

      // Formatted start and end dates
      const formattedStartDate = startDate ? startDate.split("T")[0] : "";
      const formattedEndDate = endDate ? endDate.split("T")[0] : "";

      // Reformat the interval_start value
      const reformattedData = fetchedData.map((data) => ({
        ...data,
        interval_start: new Date(data.interval_start).toLocaleString("en-GB", {
          hour12: false,
        }),
        Audio: data.avg_audio,
        Energie: data.avg_current,
        Luchtvochtigheid: data.avg_humidity,
        Luchtdruk: data.avg_pressure_hpa,
        Temperatuur: data.avg_temperature_c,
        TrillingXAs: data.avg_x,
        TrillingYAs: data.avg_y,
        TrillingZAs: data.avg_z,
      }));

      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      setLastDataUpdate(formattedDate);
      setDataStartDate(formattedStartDate);
      setDataEndDate(formattedEndDate);
      setAverageVibration([avgX, avgY, avgZ]);
      setHistorianData(reformattedData);
      setMachineLimits(limits);
      setRangeAndTicks(rangeandticks);
    } catch (error) {
      console.error("Error fetching historian data:", error);
    }
  };

  const handleApplyFilters = () => {
    setLastDataUpdate("Loading...");
    fetchData(); // Call fetchData to apply filters
  };

  return (
    <div>
      <div>
        <IconButton
          style={{ position: "absolute", top: "1%", left: "1%" }}
          onClick={() => (window.location.href = `/cockpit/historian/${prev_machine}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <IconButton
          style={{ position: "absolute", top: "1%", left: "3%" }}
          onClick={() => (window.location.href = `/cockpit`)}
        >
          <HomeIcon />
        </IconButton>
        <IconButton
          style={{ position: "absolute", top: "1%", left: "5%" }}
          onClick={() => (window.location.href = `/cockpit/historian/${next_machine}`)}
        >
          <ArrowForwardIcon />
        </IconButton>
        <div className="historian-container">
          <div className="title">Historie van Machine: {machine_number} </div>
          <div className="grid-container">
            <div className="grid-item">
              <div className="grid-item-header">
                <div className="header-content">
                  <img src={soundSVG} alt="sound" className="grid-item-img" />
                  <span className="grid-item-span"> Geluid </span>
                </div>
                <span className="graph-description"> Geluid over Periode </span>
              </div>
              <div className="grid-item-content">
                <ResponsiveContainer width={graphWidth} height={graphHeight}>
                  <LineChart data={historianData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="interval_start" tick={{ fontSize: "0.7rem", fill: "#666" }} />
                    <YAxis
                      domain={[
                        rangeAndTicksList ? rangeAndTicksList.maxaudio_upper_axis_range : 0,
                        rangeAndTicksList ? rangeAndTicksList.maxaudio_lower_axis_range : 0,
                      ]}
                      ticks={rangeAndTicksList ? rangeAndTicksList.maxaudio_ticks : []}
                      tick={{ fontSize: "1rem", fill: "#666" }}
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="Audio" stroke="#000" dot={{ r: 0.5 }} />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxaudio : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea y1={"11250"} y2={"12500"} fill="#ff9130" fillOpacity={0.5} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="grid-item">
              <div className="grid-item-header">
                <div className="header-content">
                  <img src={vibrationSVG} alt="vibration" className="grid-item-img" />
                  <span className="grid-item-span"> Trilling </span>
                </div>
                <span className="graph-description"> Gemiddelde Trilling per As </span>
              </div>
              <div className="grid-item-content">
                <div className="trilling-numbers">
                  <div>
                    <span className="number">{averageVibration[0]}</span>
                  </div>
                  <div>
                    <span className="number">{averageVibration[1]}</span>
                  </div>
                  <div>
                    <span className="number">{averageVibration[2]}</span>
                  </div>
                </div>
                <div className="dots">
                  <span>&#8226;</span>
                  <span>&#8226;</span>
                  <span>&#8226;</span>
                </div>
                <div className="labels">
                  <span>Gemiddelde van X-as</span>
                  <span>Gemiddelde van Y-as</span>
                  <span>Gemiddelde van Z-as</span>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="grid-item-header">
                <div className="header-content">
                  <img src={energySVG} alt="energy" className="grid-item-img" />
                  <span className="grid-item-span"> Energie </span>
                </div>
                <span className="graph-description"> Energie over Periode </span>
              </div>
              <div className="grid-item-content">
                <ResponsiveContainer width={graphWidth} height={graphHeight}>
                  <LineChart data={historianData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="interval_start" tick={{ fontSize: "0.7rem", fill: "#666" }} />
                    <YAxis
                      domain={[
                        rangeAndTicksList ? rangeAndTicksList.maxcurrent_upper_axis_range : 0,
                        rangeAndTicksList ? rangeAndTicksList.maxcurrent_lower_axis_range : 0,
                      ]}
                      ticks={rangeAndTicksList ? rangeAndTicksList.maxcurrent_ticks : []}
                      tick={{ fontSize: "1rem", fill: "#666" }}
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="Energie" stroke="#000" dot={{ r: 0.5 }} />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxcurrent : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y2={machineLimits ? machineLimits.mincurrent : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.mincurrent : ""}
                      y2={machineLimits ? machineLimits.mincurrent : ""}
                      fill="#ff9130"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxcurrent * 0.9 : ""}
                      y2={machineLimits ? machineLimits.maxcurrent : ""}
                      fill="#ff9130"
                      fillOpacity={0.5}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="grid-item">
              <div className="grid-item-header">
                <div className="header-content">
                  <img src={tempSVG} alt="temperature" className="grid-item-img" />
                  <span className="grid-item-span"> Temperatuur </span>
                </div>
                <span className="graph-description"> Temperatuur over Periode </span>
              </div>
              <div className="grid-item-content">
                <ResponsiveContainer width={graphWidth} height={graphHeight}>
                  <LineChart data={historianData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="interval_start" tick={{ fontSize: "0.7rem", fill: "#666" }} />
                    <YAxis
                      domain={[
                        rangeAndTicksList ? rangeAndTicksList.maxtemp_upper_axis_range : 0,
                        rangeAndTicksList ? rangeAndTicksList.maxtemp_lower_axis_range : 0,
                      ]}
                      ticks={rangeAndTicksList ? rangeAndTicksList.maxtemp_ticks : []}
                      tick={{ fontSize: "1rem", fill: "#666" }}
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="Temperatuur" stroke="#000" dot={{ r: 0.5 }} />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxtemp : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y2={machineLimits ? machineLimits.mintemp : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.mintemp * 1.1 : ""}
                      y2={machineLimits ? machineLimits.mintemp : ""}
                      fill="#ff9130"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxtemp * 0.9 : ""}
                      y2={machineLimits ? machineLimits.maxtemp : ""}
                      fill="#ff9130"
                      fillOpacity={0.5}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="grid-item">
              <div className="grid-item-header">
                <div className="header-content">
                  <img src={pressureSVG} alt="pressure" className="grid-item-img" />
                  <span className="grid-item-span"> Luchtvochtigheid </span>
                </div>
                <span className="graph-description"> Luchtvochtigheid over Periode </span>
              </div>
              <div className="grid-item-content">
                <ResponsiveContainer width={graphWidth} height={graphHeight}>
                  <LineChart data={historianData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="interval_start" tick={{ fontSize: "0.7rem", fill: "#666" }} />
                    <YAxis
                      domain={[
                        rangeAndTicksList ? rangeAndTicksList.maxhumid_upper_axis_range : 0,
                        rangeAndTicksList ? rangeAndTicksList.maxhumid_lower_axis_range : 0,
                      ]}
                      ticks={rangeAndTicksList ? rangeAndTicksList.maxhumid_ticks : []}
                      tick={{ fontSize: "1rem", fill: "#666" }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="Luchtvochtigheid"
                      stroke="#000"
                      dot={{ r: 0.5 }}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxhumid : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y2={machineLimits ? machineLimits.minhumid : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.minhumid * 1.1 : ""}
                      y2={machineLimits ? machineLimits.minhumid : ""}
                      fill="#ff9130"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxhumid * 0.93 : ""}
                      y2={machineLimits ? machineLimits.maxhumid : ""}
                      fill="#ff9130"
                      fillOpacity={0.5}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="grid-item">
              <div className="grid-item-header">
                <div className="header-content">
                  <img src={humidSVG} alt="humidity" className="grid-item-img" />
                  <span className="grid-item-span"> Luchtdruk </span>
                </div>
                <span className="graph-description"> Luchtdruk over Periode </span>
              </div>
              <div className="grid-item-content">
                <ResponsiveContainer width={graphWidth} height={graphHeight}>
                  <LineChart data={historianData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="interval_start" tick={{ fontSize: "0.7rem", fill: "#666" }} />
                    <YAxis
                      domain={[
                        rangeAndTicksList ? rangeAndTicksList.maxpressure_upper_axis_range : 0,
                        rangeAndTicksList ? rangeAndTicksList.maxpressure_lower_axis_range : 0,
                      ]}
                      ticks={rangeAndTicksList ? rangeAndTicksList.maxpressure_ticks : []}
                      tick={{ fontSize: "1rem", fill: "#666" }}
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="Luchtdruk" stroke="#000" dot={{ r: 0.5 }} />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxpressure : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y2={machineLimits ? machineLimits.minpressure : ""}
                      fill="#e7553e"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.minpressure * 1.0001 : ""}
                      y2={machineLimits ? machineLimits.minpressure : ""}
                      fill="#ff9130"
                      fillOpacity={0.5}
                    />
                    <ReferenceArea
                      y1={machineLimits ? machineLimits.maxpressure * 0.999 : ""}
                      y2={machineLimits ? machineLimits.maxpressure : ""}
                      fill="#ff9130"
                      fillOpacity={0.5}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-container">
          <div className="logo">
            <img src={datafanImage} alt="Datafan Image" style={{ width: "65%" }} />
          </div>
          <div className="filter-card">
            <div className="date-selectors-container">
              <span className="filter-label"> Datum Filter </span>
              <div className="date-selectors">
                <input
                  type="date"
                  name="startDate"
                  className="date-selector"
                  value={dataStartDate ? dataStartDate : ""}
                  onChange={(e) => setDataStartDate(e.target.value)}
                />
                <input
                  type="date"
                  name="endDate"
                  className="date-selector"
                  value={dataEndDate ? dataEndDate : ""}
                  onChange={(e) => setDataEndDate(e.target.value)}
                />
              </div>
              <div className="filter-button-group">
                <button className="filter-button" onClick={handleApplyFilters}>
                  FILTERS TOEPASSEN
                </button>
              </div>
            </div>
            <div className="additional-text"> Laatste data update: {lastDataUpdate} </div>
          </div>
          <div className="filter-card" style={{ height: "100%" }}>
            <Uitgevoerd machineNumber={machine_number} />
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes declaration
Historian.propTypes = {
  machine_number: PropTypes.string,
};

export default Historian;
