import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceArea,
} from "recharts";

const data = [
  { 1: 4000, 2: 2400, amt: 2400 },
  { 1: 3000, 2: 1398, amt: 2210 },
  { 1: 2000, 2: 9800, amt: 2290 },
  { 1: 2780, 2: 3908, amt: 2000 },
  { 1: 1890, 2: 4800, amt: 2181 },
  { 1: 2390, 2: 3800, amt: 2500 },
  { 1: 3490, 2: 4300, amt: 2100 },
];

const TestContainer = () => (
  <LineChart
    width={500}
    height={300}
    data={data}
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" dataKey="1" stroke="#000" activeDot={{ r: 8 }} />
    <Line type="monotone" dataKey="2" stroke="#000" />
    <Line type="monotone" dataKey="amt" stroke="#000" />
    <ReferenceArea y1={9000} fill="#e7553e" fillOpacity={0.5} />
    <ReferenceArea y2={1000} fill="#e7553e" fillOpacity={0.5} />
    <ReferenceArea y1={1000} y2={1500} fill="#ff9130" fillOpacity={0.5} />
    <ReferenceArea y1={8500} y2={9000} fill="#ff9130" fillOpacity={0.5} />
  </LineChart>
);

export default TestContainer;
