import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Grid,
  TextField,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import SessionManager from "../../Authentication/SessionManager/session_manager";
import datafanImage from "../../../assets/images/datafan/2023-12-Logo-Datafan.png";

// Import the parseMachineIdentifier utility
import parseMachineIdentifier from "./parseInformation";
// Import the processFormData utility
import processFormData from "./formDataProcessor";

const theme = createTheme({
  palette: {
    primary: {
      main: "#32aca2",
    },
  },
});

const InputPanel = () => {
  const navigate = useNavigate();
  const { machine_factory_identifier } = useParams();

  const fieldConfig = {
    shift: {
      label: "Dienst",
      options: ["Ochtend", "Middag", "Nacht"],
    },
    taskCategory: {
      label: "Taak Categorie",
      options: ["Onderhoud", "Reparatie", "Inspectie"],
    },
    task: {
      label: "Taak",
      options: ["Motor vervangen", "Aandrijfriem", "Smeerolie ververst", "Inspectie", "Anders"],
    },
  };

  const initialDropdownValues = Object.keys(fieldConfig).reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {});
  initialDropdownValues.operatorName = ""; // State for operatorName
  initialDropdownValues.location = "Informatie op aan het halen..."; // Default state for location
  initialDropdownValues.machineType = "Informatie op aan het halen..."; // Default state for machineType

  const [dropdownValues, setDropdownValues] = useState(initialDropdownValues);
  const [loading, setLoading] = useState(false);
  const [customTask, setCustomTask] = useState("");

  useEffect(() => {
    SessionManager.checkActiveSession();
    SessionManager.setupActivityTracking();

    return () => {
      SessionManager.removeActivityTracking();
    };
  }, []);

  useEffect(() => {
    const fetchMachineInfo = async () => {
      const loggedInUser = SessionManager.getLoggedInUser()?.email || "Onbekende Gebruiker";

      try {
        // Set the operator name immediately
        setDropdownValues((prev) => ({
          ...prev,
          operatorName: loggedInUser,
        }));

        // Use the utility function to parse the identifier
        const { location, machineType } = await parseMachineIdentifier(
          machine_factory_identifier,
          loggedInUser
        );

        // Set the parsed values in the state
        setDropdownValues((prev) => ({
          ...prev,
          machineType,
          location,
        }));
      } catch (error) {
        console.error("Error parsing machine factory identifier:", error.message);
        // Fallback to only setting the operatorName if parsing fails
        setDropdownValues((prev) => ({
          ...prev,
          location: "Probleem bij ophalen locatie",
          machineType: "Problem bij ophalen machine",
        }));
      }
    };

    // Call the async function
    fetchMachineInfo();
  }, [machine_factory_identifier]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDropdownValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCustomTaskChange = (event) => {
    setCustomTask(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = {
      operatorName: dropdownValues.operatorName,
      shift: dropdownValues.shift,
      taskCategory: dropdownValues.taskCategory,
      task: dropdownValues.task === "Anders" ? customTask : dropdownValues.task,
      machineFactoryIdentifier: machine_factory_identifier, // Sending machine identifier instead of location and machineType
    };

    // Retrieve logged-in user
    const loggedInUser = SessionManager.getLoggedInUser()?.email || "Onbekende Gebruiker";

    // Send the form data to the external function for processing
    await processFormData(formData, machine_factory_identifier, loggedInUser);

    setTimeout(() => {
      setLoading(false);
      // Navigate to the success message page
      navigate(`/success/${machine_factory_identifier}`);
    }, 2000);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#ffffff",
            padding: "2rem",
            borderRadius: "10px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            marginTop: "10vh",
          }}
        >
          <Box
            component="img"
            src={datafanImage}
            alt="Datafan Logo"
            sx={{
              width: {
                xs: "80%",
                sm: "60%",
                md: "30%",
              },
              marginBottom: "1.5rem",
            }}
          />
          <Box
            sx={{
              width: {
                xs: "60%",
                sm: "40%",
                md: "15%",
              },
              borderBottom: "2px solid #32aca2",
              my: 2,
              borderRadius: "15%",
            }}
          />
          <Typography component="h1" variant="h5">
            Cockpit invoer module
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: "90%" }}>
            <Grid container spacing={2}>
              {/* Render Read-Only Fields for Location, Machine Type, and Operator Name */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
                  Locatie
                </Typography>
                <TextField
                  name="location"
                  value={dropdownValues.location}
                  fullWidth
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  sx={{
                    backgroundColor: "#e0e0e0",
                    "& .MuiOutlinedInput-root": {
                      height: "3.5rem",
                      border: "none",
                      "&.Mui-focused fieldset": {
                        borderColor: "#32aca2",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
                  Machine
                </Typography>
                <TextField
                  name="machineType"
                  value={dropdownValues.machineType}
                  fullWidth
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  sx={{
                    backgroundColor: "#e0e0e0",
                    "& .MuiOutlinedInput-root": {
                      height: "3.5rem",
                      border: "none",
                      "&.Mui-focused fieldset": {
                        borderColor: "#32aca2",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
                  Operator
                </Typography>
                <TextField
                  name="operatorName"
                  value={dropdownValues.operatorName}
                  fullWidth
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  sx={{
                    backgroundColor: "#e0e0e0",
                    "& .MuiOutlinedInput-root": {
                      height: "3.5rem",
                      border: "none",
                      "&.Mui-focused fieldset": {
                        borderColor: "#32aca2",
                      },
                    },
                  }}
                />
              </Grid>

              {/* Render Editable Dropdown Fields for Other Configurations */}
              {Object.entries(fieldConfig).map(([fieldName, config]) => (
                <Grid item xs={12} key={fieldName}>
                  <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
                    {config.label}
                  </Typography>

                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{`Selecteer ${config.label}`}</InputLabel>
                    <Select
                      label={`Selecteer ${config.label}`}
                      name={fieldName}
                      value={dropdownValues[fieldName]}
                      onChange={handleChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          height: "3.5rem",
                          border: "none",
                          "&.Mui-focused fieldset": {
                            borderColor: "#32aca2",
                          },
                        },
                      }}
                    >
                      {config.options.map((option, idx) => (
                        <MenuItem key={idx} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {fieldName === "task" && dropdownValues[fieldName] === "Anders" && (
                    <TextField
                      label="Specificeer Taak"
                      value={customTask}
                      onChange={handleCustomTaskChange}
                      fullWidth
                      variant="outlined"
                      sx={{
                        mt: 2,
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#32aca2",
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
              ))}
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#32aca3",
                color: "#ffffff",
                mt: 3,
                mb: 2,
                "&:hover": {
                  backgroundColor: "#289a91",
                },
              }}
              disabled={loading}
            >
              {loading ? "Laden..." : "Bevestig"}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default InputPanel;
