import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// images
import datafanImage from "../../../assets/images/datafan/2023-12-Logo-Datafan.png";

//import custom scripts
import Authenticate from "../Login/authenticate.js";

// Prop validation for the RedirectMessage component
function RedirectMessage({ message }) {
  return (
    <Typography color="text.primary" align="center" sx={{ mb: 2 }}>
      {message}
    </Typography>
  );
}

// Prop validation for the RedirectMessage component
RedirectMessage.propTypes = {
  message: PropTypes.string.isRequired, // message prop should be a required string
};

function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const [sameValueStatus, setSameValueStatus] = useState(null);
  const [redirectMessage, setRedirectMessage] = useState("");

  const { account_identifier } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const new_password = data.get("newpassword");
    const repeat_new_password = data.get("repeatnewpassword");

    if (new_password !== repeat_new_password) {
      setLoginStatus(null);
      setSameValueStatus(false);
    } else {
      setSameValueStatus(null);
      setLoading(true);

      try {
        const request_verification = await Authenticate.verify_request(
          account_identifier,
          new_password
        );
        if (!request_verification) {
          console.log("Failed");
          setLoginStatus(true);
        } else {
          console.log("Success");
          setRedirectMessage("Wachtwoord is correct aangepast. U wordt doorgestuurd...");
          setTimeout(() => {
            window.location.href = "/home";
          }, 3000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#32aca2",
      },
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px", // Add margin at the bottom for spacing
            marginTop: "5vh",
          }}
        >
          <img src={datafanImage} alt="Datafan Image" style={{ width: "20%" }} />
          <Box
            sx={{
              width: "15%",
              borderBottom: "2px solid #32aca2",
              my: 2,
              borderRadius: "15%",
            }}
          />
          <Avatar
            sx={{
              m: 1,
              bgcolor: "#32aca2",
              marginTop: "0px",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Wachtwoord resetten
          </Typography>
          {redirectMessage && <RedirectMessage message={redirectMessage} />}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              width: "40%",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="newpassword"
              label="Nieuw Wachtwoord"
              type="password"
              name="newpassword"
              autoFocus
              sx={{
                "& label.Mui-focused": { color: "#32aca2" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": { borderColor: "#32aca2" },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="repeatnewpassword"
              label="Nieuw Wachtwoord Herhalen"
              type="password"
              id="repeatnewpassword"
              sx={{
                "& label.Mui-focused": { color: "#32aca2" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": { borderColor: "#32aca2" },
                },
              }}
            />
            {sameValueStatus === false && (
              <Typography color="error" align="center" sx={{ marginY: 1, fontWeight: "bold" }}>
                <WarningIcon sx={{ verticalAlign: "bottom", marginRight: 1 }} />
                De velden komen niet overeen.
              </Typography>
            )}
            {loginStatus === false && (
              <Typography color="error" align="center" sx={{ marginY: 1, fontWeight: "bold" }}>
                <WarningIcon sx={{ verticalAlign: "bottom", marginRight: 1 }} />
                Er is iets fout gegaan. Probeer het later opnieuw.
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ backgroundColor: "#32aca2", mt: 3, mb: 2, color: "white" }}
              disabled={loading} // Disable the button when loading
            >
              {loading ? "Laden..." : "Reset Wachtwoord"} {/* Display "Laden..." when loading */}
            </Button>
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
          Copyright ©{" "}
          <Link color="inherit" href="https://datafan.nl/">
            Datafan.nl
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </ThemeProvider>
  );
}

export default ResetPassword;
