import React from "react";
import { Grid, Typography, Button, Box, Container } from "@mui/material";
import backgroundImage from "../../../assets/images/backgroundImage.png"; // Replace with your actual image import

const HomeHeader = () => {
  return (
    <Box
      sx={{
        position: "fixed", // Fix the header to the top
        top: 0,
        left: 0,
        width: "100%", // Make the header take up the full width
        height: "100vh", // Full viewport height
        background: "linear-gradient(to bottom, #f0fafa, #33ada3)", // Gradient background
        display: "flex",
        alignItems: "center",
        zIndex: 1, // Make sure it's behind the scrolling content
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Left Section: Heading and Buttons */}
          <Grid item xs={12} md={6}>
            <Typography variant="h2" sx={{ fontWeight: "bold", color: "#143d5e" }}>
              Datafan: innovatieve data-gedreven oplossingen.
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 4, color: "#143d5e" }}>
              Uw machinepark digitaliseren? Krijg meer grip op de productiviteit en het onderhoud
              van uw machines met data uit sensoren.
            </Typography>
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#143d5e", // Dark blue
                  color: "#ffffff", // White text
                  marginRight: 2,
                  paddingX: 3,
                  paddingY: 1.5,
                  "&:hover": { backgroundColor: "#0d2e47" }, // Darker blue on hover
                }}
                href="/diensten"
              >
                &gt; Onze diensten
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#143d5e",
                  borderColor: "#143d5e",
                  paddingX: 3,
                  paddingY: 1.5,
                  "&:hover": { borderColor: "#0d2e47", color: "#0d2e47" }, // Darker blue on hover
                }}
                href="/updates"
              >
                &gt; Onze updates
              </Button>
            </Box>
          </Grid>

          {/* Right Section: Image */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={backgroundImage} // Replace with actual image source
              alt="Datafan Solutions"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeHeader;
