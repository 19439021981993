import CryptoJS from "crypto-js";
import { API_KEY } from "./config";

class Authenticate {
  static perform_login(username, password) {
    // Encrypt the password before sending
    const encrypted_password = this.encrypt_password(password);

    // perform login_request
    return this.send_login_request(username, encrypted_password)
      .then((authentication_request_status) => {
        // Update local storage based on authentication result
        localStorage.setItem(
          "authentication_request_status",
          authentication_request_status.toString()
        );

        // Resolve with authentication status
        return authentication_request_status;
      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
        throw error; // re-throw the error to propagate it to the next catch block
      });
  }

  static encrypt_password(password) {
    return CryptoJS.SHA512(password).toString();
  }

  static send_login_request(username, password) {
    const url = `https://datafan-login-api.azurewebsites.net/api/datafan-website-login-api?
code=${encodeURIComponent(API_KEY)}&
username=${encodeURIComponent(username)}&
password=${encodeURIComponent(password)}
`;

    return fetch(url)
      .then((response) => {
        return response.status === 200;
      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
        throw error; // re-throw the error to propagate it to the next catch block
      });
  }

  static send_2fa_request(auth_code) {
    const url = `https://datafan-login-api.azurewebsites.net/api/datafan-website-login-api?
code=${encodeURIComponent(API_KEY)}&
two_factor_authentication_code=${encodeURIComponent(auth_code)}
            `;

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          return 401;
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data here
        return data; // pass the data along
      })
      .catch((error) => {
        // Handle errors here
        throw error; // re-throw the error to propagate it to the next catch block
      });
  }

  static reset_password_request(username) {
    if (username) {
      const url =
        `https://datafan-login-api.azurewebsites.net/api/datafan-website-login-api?` +
        `code=${encodeURIComponent(API_KEY)}&` +
        `reset_request=true&username=${encodeURIComponent(username)}`;

      return fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text(); // Read response as text
        })
        .then((data) => {
          // Handle empty response
          if (!data) {
            throw new Error("Empty response from the server");
          }
          // Assuming the API returns 't' or 'f'
          const success = data === "t"; // Convert 't' or 'f' to true or false
          return { success }; // Return a JSON object with the success value
        })
        .catch((error) => {
          // Handle errors here
          throw error; // re-throw the error to propagate it to the next catch block
        });
    }
    return { success: false }; // If no username provided, return false
  }

  static verify_request(account_identifier, new_pass) {
    const encrypted_new_pass = this.encrypt_password(new_pass);

    const url = `https://datafan-login-api.azurewebsites.net/api/datafan-website-login-api?
code=${encodeURIComponent(API_KEY)}&
reset_validation_request=${encodeURIComponent(account_identifier)}&
new_pass=${encodeURIComponent(encrypted_new_pass)}
`;

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return true;
      })
      .catch((error) => {
        // Handle errors here
        throw error; // re-throw the error to propagate it to the next catch block
      });
  }
}

export default Authenticate;
