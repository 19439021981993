import React from "react";
import {
  Box,
  createTheme,
  ThemeProvider,
  Container,
  Typography,
  Breadcrumbs,
  Grid,
  Button,
  Link,
} from "@mui/material";
import { useParams, Link as RouterLink } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "../../Footer";
import Header from "../../Header";
import postMapping from "../postMapping"; // Import your postMapping
import contactUs from "../../../../assets/images/contactus.jpg";

// Create a global MUI theme with standardized font sizes
const theme = createTheme({
  typography: {
    h4: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.6,
      color: "#333",
    },
    button: {
      textTransform: "none",
    },
  },
});

const BlogTemplateLarge = () => {
  const { blog } = useParams(); // Access the route parameter

  // Find the post by the key matching the URL parameter
  const post = postMapping.find((p) => p.key === blog);

  // If no post found, return a "not found" message
  if (!post) {
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Header />
          <Container maxWidth="md">
            <Typography variant="h4" align="center" sx={{ marginTop: 4 }}>
              Oeps! Deze update is niet gevonden.
            </Typography>
          </Container>
          <Footer />
        </Box>
      </ThemeProvider>
    );
  }

  // Find the index of the current post
  const currentIndex = postMapping.findIndex((p) => p.key === blog);

  // Find the previous and next posts based on the current index
  const previousPost = currentIndex > 0 ? postMapping[currentIndex - 1] : null;
  const nextPost = currentIndex < postMapping.length - 1 ? postMapping[currentIndex + 1] : null;

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Box sx={{ backgroundColor: "#ffffff", paddingTop: 10 }}>
          {/* Breadcrumbs (Full Width) */}
          <Container maxWidth="lg" sx={{ marginBottom: 4, left: 0, minWidth: "100%" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                component={RouterLink}
                to="/"
                underline="hover"
                color="inherit"
                sx={{ fontSize: "1rem", color: "#143d5e" }}
              >
                Home
              </Link>
              <Link
                component={RouterLink}
                to="/updates"
                underline="hover"
                color="inherit"
                sx={{ fontSize: "1rem", color: "#143d5e" }}
              >
                Updates
              </Link>
              <Typography sx={{ fontSize: "1rem", color: "#143d5e", fontWeight: 500 }}>
                {post.title}
              </Typography>
            </Breadcrumbs>
          </Container>
          <Container sx={{ maxWidth: "60%!important", marginLeft: "auto", marginRight: "auto" }}>
            {/* Title Section */}
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
            >
              {post.title}
            </Typography>

            {/* Metadata */}
            <Typography
              variant="body1"
              align="center"
              sx={{
                fontSize: "1rem",
                color: "#999",
                marginBottom: 2,
              }}
            >
              {`${post.date} | ${post.readTime} | ${post.author}`}
            </Typography>

            {/* Teal line under the title */}
            <Box
              sx={{
                width: "80px",
                height: "4px",
                backgroundColor: "#33ada3",
                margin: "16px auto 24px auto",
              }}
            />

            {/* Sections Before Image */}
            {post.sectionsBeforeImage.map((section, index) => (
              <Box key={index} sx={{ marginBottom: 4 }}>
                <Typography variant="h5" sx={{ fontWeight: 600, marginBottom: 2 }}>
                  {section.title}
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: "1.6", color: "#333" }}>
                  {section.content}
                </Typography>
              </Box>
            ))}

            {/* Image Section */}
            <Box
              component="img"
              src={post.image}
              alt={post.title}
              sx={{
                width: "100%",
                maxWidth: "600px",
                borderRadius: 4,
                marginBottom: 4,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />

            {/* Sections After Image */}
            {post.sectionsAfterImage.map((section, index) => (
              <Box key={index} sx={{ marginBottom: 4 }}>
                <Typography variant="h5" sx={{ fontWeight: 600, marginBottom: 2 }}>
                  {section.title}
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: "1.6", color: "#333" }}>
                  {section.content}
                </Typography>
              </Box>
            ))}

            {/* Previous and Next Navigation */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 6,
                marginBottom: 6,
              }}
            >
              {previousPost && (
                <Button
                  component={RouterLink}
                  to={`/updates/${previousPost.key}`}
                  startIcon={<ArrowBackIcon />}
                  sx={{ fontWeight: 700, textTransform: "none" }}
                >
                  {previousPost.title}
                </Button>
              )}
              {nextPost && (
                <Button
                  component={RouterLink}
                  to={`/updates/${nextPost.key}`}
                  endIcon={<ArrowForwardIcon />}
                  sx={{ fontWeight: 700, textTransform: "none" }}
                >
                  {nextPost.title}
                </Button>
              )}
            </Box>
          </Container>

          {/* Contact Section */}
          <Box
            sx={{
              backgroundColor: "#6BD1CA",
              borderRadius: 4,
              paddingY: 6,
              paddingX: 4,
              marginTop: 8,
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              width: "100vw",
              position: "relative",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={4} alignItems="center">
                {/* Left Side: Text and Button */}
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginBottom: 2, color: "#000" }}
                  >
                    Heb je een vraag of een uitdaging voor ons?
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 4, color: "#333" }}>
                    Wij staan voor je klaar! Neem gerust contact op.
                  </Typography>

                  {/* Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#33ada3",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                    href="/contact"
                  >
                    &gt; Naar contactformulier
                  </Button>
                </Grid>

                {/* Right Side: Image */}
                <Grid item xs={12} md={6}>
                  <Box
                    component="img"
                    src={contactUs}
                    alt="Contact Us"
                    sx={{
                      width: "100%",
                      borderRadius: 4,
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default BlogTemplateLarge;
