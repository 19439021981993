import * as React from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// images
import datafanImage from "../../../assets/images/datafan/2023-12-Logo-Datafan.png";

// import custom scripts
import Authenticate from "../Login/authenticate.js";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#32aca2",
    },
  },
});

export default function ForgotPassword() {
  const [loading, setLoading] = React.useState(false); // State for loading status
  const [emailSent, setEmailSent] = React.useState(false); // State for email sent status

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    setLoading(true);
    const reset_password_request = await Authenticate.reset_password_request(email);
    setLoading(false);

    if (reset_password_request) {
      setEmailSent(true);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px", // Add margin at the bottom for spacing
            marginTop: "5vh",
          }}
        >
          <img src={datafanImage} alt="Datafan Image" style={{ width: "20%" }} />
          <Box
            sx={{
              width: "15%",
              borderBottom: "2px solid #32aca2",
              my: 2,
              borderRadius: "15%",
            }}
          />
          <Avatar
            sx={{
              m: 1,
              bgcolor: "#32aca2",
              marginTop: "0px",
            }}
          />
          {emailSent ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h5" gutterBottom>
                Email is verstuurd
              </Typography>
              <Typography variant="body1" align="center">
                Er is een e-mail verstuurd naar uw adres met instructies voor het resetten van uw
                wachtwoord.
              </Typography>
              <Button
                component={Link} // Use Link component for routing
                to="/home" // Link to the home page
                fullWidth
                variant="contained"
                sx={{ backgroundColor: "#32aca2", mt: 3, mb: 2, color: "white" }}
              >
                Terug naar home
              </Button>
            </Box>
          ) : (
            <Box>
              <Typography component="h1" variant="h5" align="center">
                Wachtwoord reset aanvragen
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  mt: 1,
                  width: "100%",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email adres"
                  type="email" // Change type to email for better validation
                  name="email"
                  autoFocus
                  sx={{
                    "& label.Mui-focused": { color: "#32aca2" },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": { borderColor: "#32aca2" },
                    },
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ backgroundColor: "#32aca2", mt: 3, mb: 2, color: "white" }}
                  disabled={loading} // Disable the button when loading
                >
                  {loading ? "Laden..." : "Aanvraag opsturen"}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
