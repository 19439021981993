/**
 * Function to parse the machine_factory_identifier and extract the location and machine type.
 * @param {string} identifier - The machine factory identifier in the specified format.
 * @param {string} user - The user parameter to be used in the API request.
 * @returns {Promise<{ location: string, machineType: string }>} Parsed values for location and machineType.
 */

import SessionManager from "../../Authentication/SessionManager/session_manager";
import { API_KEY } from "./config";

const parseMachineIdentifier = async (identifier, user) => {
  const company_key_encrypted = localStorage.getItem("company_key");
  const company_key = SessionManager.getCompanyApiKey(company_key_encrypted);
  const database_key = SessionManager.decryptDatabase();

  const url =
    `https://datafan-website-dashboards.azurewebsites.net/api/datafan_website_operator_module?` +
    `code=${encodeURIComponent(API_KEY)}&` +
    `api_key=${encodeURIComponent(company_key)}&` +
    `user=${encodeURIComponent(user)}&` +
    `identifier=${encodeURIComponent(identifier)}&` +
    `database_id=${encodeURIComponent(database_key)}`;

  try {
    // Await the fetch response
    const response = await fetch(url);
    if (!response.ok) {
      console.error(`Error: ${response.status}`);
      // Return fallback values if the response is not OK
      return { location: "Onbekende locatie", machineType: "Onbekende machine" };
    }

    const results = await response.json();

    console.log(results);
    // Extract the desired values from the response
    const location = results[0].company_name || "Onbekende locatie";
    const machineType = results[0].machine_name || "Onbekende machine";

    return { location, machineType };
  } catch (error) {
    console.error("Fetch error:", error);
    // Handle errors and return fallback values
    return { location: "Onbekende locatie", machineType: "Onbekende machine" };
  }
};

export default parseMachineIdentifier;
