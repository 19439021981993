import React from "react";
import {
  Box,
  createTheme,
  ThemeProvider,
  Container,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import contactUs from "../../../assets/images/contactus.jpg";
import Footer from "../Footer";
import Header from "../Header";
import InnovationIcon from "../../../assets/images/ICOON Innovatie.png";
import EfficiencyIcon from "../../../assets/images/ICOON Efficientie.png";
import CustomerMindedIcon from "../../../assets/images/ICOON Klantgericht.png";
import TrustworthyIcon from "../../../assets/images/ICOON Betrouwbaar.png";
import { LinkedIn } from "@mui/icons-material";

// Create a global MUI theme with standardized font sizes
const theme = createTheme({
  typography: {
    h3: {
      fontSize: "3rem", // Match the Home component h4 size
      fontWeight: 700, // Bold
    },
    h4: {
      fontSize: "2rem", // Match the Home component h4 size
      fontWeight: 700, // Bold
    },
    h5: {
      fontSize: "1.5rem", // Smaller section headers
      fontWeight: 600, // Slightly less bold
    },
    h6: {
      fontSize: "1.5rem", // Smaller section headers
      fontWeight: 600, // Slightly less bold
    },
    body1: {
      fontSize: "1rem", // Standard body text size
      lineHeight: 1.6, // Consistent line-height for readability
      color: "#333", // Standard text color
    },
    button: {
      textTransform: "none", // Ensure buttons are not uppercase
    },
  },
});

const AboutUs = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Box sx={{ backgroundColor: "#ffffff", paddingTop: 10 }}>
          {/* Title Section */}
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
          >
            Over Ons
          </Typography>

          {/* Teal line under the title */}
          <Box
            sx={{
              width: "80px",
              height: "4px",
              backgroundColor: "#33ada3",
              margin: "16px auto 24px auto",
            }}
          />

          <Typography
            variant="body1"
            align="center"
            sx={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              maxWidth: "60%",
              marginX: "auto",
              marginBottom: 4,
              color: "#333",
            }}
          >
            Bij Datafan werken we nauw samen met onze klanten om op maat gemaakte oplossingen te
            ontwikkelen die écht verschil maken. Onze aanpak is gericht op: innovatie, efficiëntie,
            klantgerichtheid en betrouwbaarheid. Deze vier kernwaarden vormen de basis van onze
            aanpak. Hieronder lichten we ze toe.
          </Typography>

          {/* Core Values Grid */}
          <Box
            sx={{ textAlign: "center", marginTop: 8, marginBottom: 8, maxWidth: "80%", mx: "auto" }}
          >
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#33ada3", // Dark blue background
                    borderRadius: 4,
                    padding: 3,
                    textAlign: "center",
                    color: "#ffffff", // White text and icons
                  }}
                >
                  {/* Icon */}
                  <Box component="span" sx={{ fontSize: 48, display: "block", marginBottom: 2 }}>
                    <Box
                      component="img"
                      src={InnovationIcon} // Replace with actual image source
                      alt="Innovation Icon"
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Innovatie
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#33ada3",
                    borderRadius: 4,
                    padding: 3,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  {/* Icon */}
                  <Box component="span" sx={{ fontSize: 48, display: "block", marginBottom: 2 }}>
                    <Box
                      component="img"
                      src={EfficiencyIcon} // Replace with actual image source
                      alt="Innovation Icon"
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Efficiëntie
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#33ada3",
                    borderRadius: 4,
                    padding: 3,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  {/* Icon */}
                  <Box component="span" sx={{ fontSize: 48, display: "block", marginBottom: 2 }}>
                    <Box
                      component="img"
                      src={CustomerMindedIcon} // Replace with actual image source
                      alt="Innovation Icon"
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Klantgericht
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#33ada3",
                    borderRadius: 4,
                    padding: 3,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  {/* Icon */}
                  <Box component="span" sx={{ fontSize: 48, display: "block", marginBottom: 2 }}>
                    <Box
                      component="img"
                      src={TrustworthyIcon} // Replace with actual image source
                      alt="Innovation Icon"
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Betrouwbaar
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
          >
            Onze kernwaarden
          </Typography>

          <Box
            sx={{
              width: "60px",
              height: "4px",
              backgroundColor: "#33ada3",
              margin: "16px auto 24px auto",
            }}
          />

          <Box sx={{ maxWidth: "800px", mx: "auto", mb: 4, px: 2 }}>
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                component="h3"
                sx={{ color: "#333", fontWeight: "bold", mb: 1 }}
              >
                Innovatie
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", lineHeight: "1.6", color: "#555" }}
              >
                Bij Datafan kijken we altijd vooruit. We passen de nieuwste technologieën en
                methoden toe. Dit betekent dat we niet alleen bestaande uitdagingen oplossen, maar
                ook actief meedenken over hoe je organisatie zich kan voorbereiden op de toekomst.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                component="h3"
                sx={{ color: "#333", fontWeight: "bold", mb: 1 }}
              >
                Efficiëntie
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", lineHeight: "1.6", color: "#555" }}
              >
                We begrijpen dat tijd en middelen kostbaar zijn. Daarom streven we ernaar om elke
                oplossing zo effectief mogelijk te maken. Voor jou als klant betekent dit dat de
                oplossingen zijn afgestemd op jouw specifieke situatie. Zo dragen ze optimaal bij
                aan je bedrijfsprocessen.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                component="h3"
                sx={{ color: "#333", fontWeight: "bold", mb: 1 }}
              >
                Klantgericht
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", lineHeight: "1.6", color: "#555" }}
              >
                Elke klant is uniek, en bij Datafan staat jouw specifieke behoefte centraal. We
                werken nauw samen met jou om te zorgen dat onze oplossingen perfect aansluiten op
                jouw wensen en uitdagingen. Jouw succes is onze prioriteit.
              </Typography>
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                component="h3"
                sx={{ color: "#333", fontWeight: "bold", mb: 1 }}
              >
                Betrouwbaar
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", lineHeight: "1.6", color: "#555" }}
              >
                Je kunt rekenen op Datafan voor kwaliteit en consistentie. We bouwen sterke,
                duurzame relaties met onze klanten door te leveren wat we beloven. Voor jou betekent
                dit dat je altijd kunt vertrouwen op stabiele, veilige en effectieve oplossingen.
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
          >
            Ons Team
          </Typography>
          <Box
            sx={{
              width: "60px",
              height: "4px",
              backgroundColor: "#33ada3",
              margin: "16px auto 24px auto",
            }}
          />
          <Box sx={{ maxWidth: "800px", mx: "auto", mb: 4, px: 2 }}>
            <Typography
              variant="body1"
              align="left"
              sx={{
                fontSize: "1.25rem",
                lineHeight: "1.6",
                color: "#333",
                marginBottom: 2,
              }}
            >
              Bij Datafan zijn we gepassioneerd over data en de kracht ervan om bedrijven vooruit te
              helpen. Ons toegewijd kernteam staat voor je klaar om jouw data-uitdagingen om te
              zetten in effectieve oplossingen. We stellen graag de volgende ondernemers aan je
              voor:
            </Typography>

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h6"
                component="h3"
                sx={{ color: "#333", fontWeight: "bold", mb: 1 }}
              >
                Noah Lammertsma – Lead Data Engineer
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", lineHeight: "1.6", color: "#555" }}
              >
                Noah is verantwoordelijk voor alle technische innovaties binnen Datafan. Met zijn
                focus op efficiëntie en robuuste oplossingen zorgt hij ervoor dat onze klanten
                altijd kunnen vertrouwen op slimme technologieën die hun data-uitdagingen oplossen.
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="h6"
                component="h3"
                sx={{ color: "#333", fontWeight: "bold", mb: 1 }}
              >
                Robert Wit – Business Development Manager
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", lineHeight: "1.6", color: "#555" }}
              >
                Robert is de schakel tussen onze klanten en het technische team. Hij vertaalt
                klantbehoeften naar concrete data-oplossingen en zorgt ervoor dat deze aansluiten
                bij de strategische doelen van jouw bedrijf.
                <br />
                <br />
                Heb je vragen, opmerkingen of suggesties? Noah en Robert staan klaar om je verder te
                helpen. We horen graag van je!
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
          >
            Volg Datafan op social media!{" "}
            <Box
              component="span"
              sx={{
                display: "inline-flex",
                alignItems: "baseline", // Align the box baseline to line up with text.
                position: "relative",
                top: "0.2em", // Adjust this value slightly until the icon aligns well with the bottom.
              }}
            >
              <LinkedIn sx={{ fontSize: "2rem", color: "#33ada3" }} />
            </Box>
          </Typography>

          <Box
            sx={{
              backgroundColor: "#6BD1CA",
              borderRadius: 4,
              paddingY: 6,
              paddingX: 4,
              marginTop: 8,
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              width: "100vw",
              position: "relative",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={4} alignItems="center">
                {/* Left Side: Text and Button */}
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginBottom: 2, color: "#000" }}
                  >
                    Heb je een vraag of een uitdaging voor ons?
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 4, color: "#333" }}>
                    Wij staan voor je klaar! Neem gerust contact op.
                  </Typography>

                  {/* Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#33ada3", // Change to the desired color
                      padding: "10px 20px", // Padding adjustments
                      textTransform: "none", // Prevent text transformation
                      fontWeight: "bold", // Bold text
                    }}
                    href="/contact" // Replace with the desired link
                  >
                    &gt; Naar contactformulier
                  </Button>
                </Grid>

                {/* Right Side: Image */}
                <Grid item xs={12} md={6}>
                  <Box
                    component="img"
                    src={contactUs}
                    alt="Person working on laptop"
                    sx={{
                      width: "100%",
                      borderRadius: 4,
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default AboutUs;
