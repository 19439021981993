import React, { useState } from "react";
import {
  Box,
  createTheme,
  ThemeProvider,
  Container,
  Typography,
  Grid,
  Button,
  Pagination,
} from "@mui/material";
import contactUs from "../../../assets/images/contactus.jpg";
import Footer from "../Footer";
import Header from "../Header";
import BlogTemplateSmall from "./BlogTemplate/small";
import blogConfigData from "./blogConfig.json";
import postMapping from "./postMapping";

// Create a global MUI theme with standardized font sizes
const theme = createTheme({
  typography: {
    h4: {
      fontSize: "2rem", // Match the Home component h4 size
      fontWeight: 700, // Bold
    },
    h5: {
      fontSize: "1.5rem", // Smaller section headers
      fontWeight: 600, // Slightly less bold
    },
    body1: {
      fontSize: "1rem", // Standard body text size
      lineHeight: 1.6, // Consistent line-height for readability
      color: "#333", // Standard text color
    },
    button: {
      textTransform: "none", // Ensure buttons are not uppercase
    },
  },
});

const Blog = () => {
  const [page, setPage] = useState(1);
  const postsPerPage = 9;
  const totalPosts = blogConfigData.length;
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  // Determine which posts to display based on pagination
  const displayedPosts = blogConfigData.slice((page - 1) * postsPerPage, page * postsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Box sx={{ backgroundColor: "#ffffff", paddingTop: 10 }}>
          <Container maxWidth="md">
            {/* Title Section */}
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
            >
              Onze Updates
            </Typography>

            {/* Teal line under the title */}
            <Box
              sx={{
                width: "80px",
                height: "4px",
                backgroundColor: "#33ada3",
                margin: "16px auto 24px auto",
              }}
            />

            <Typography
              variant="body1"
              align="center"
              sx={{
                fontSize: "1.25rem",
                lineHeight: "1.6",
                maxWidth: "60%",
                marginX: "auto",
                marginBottom: 4,
                color: "#333",
              }}
            >
              Bij Datafan draait alles om data en de impact ervan op bedrijf productieprocessen. We
              volgen de nieuwste trends en ontwikkelingen in de wereld van data-analyse en
              technologie op de voet. In onze blogs delen we onze unieke visie op de belangrijkste
              thema&apos;s binnen data en innovatie. Hieronder vind je onze recente updates.
            </Typography>

            {/* Blog posts */}
            <Box display="flex" justifyContent="center" flexWrap="wrap">
              {displayedPosts.map((postKey, index) => {
                const postDetails = postMapping.find((post) => post.key === postKey);
                return postDetails ? (
                  <BlogTemplateSmall key={index} postDetails={postDetails} />
                ) : null;
              })}
            </Box>

            {/* Pagination */}
            <Box display="flex" justifyContent="center" marginTop={4}>
              <Pagination count={totalPages} page={page} onChange={handleChange} color="primary" />
            </Box>

            <Box
              sx={{
                backgroundColor: "#6BD1CA",
                borderRadius: 4,
                paddingY: 6,
                paddingX: 4,
                marginTop: 8,
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
                width: "100vw",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center">
                  {/* Left Side: Text and Button */}
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", marginBottom: 2, color: "#000" }}
                    >
                      Heb je een vraag of een uitdaging voor ons?
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 4, color: "#333" }}>
                      Wij staan voor je klaar! Neem gerust contact op.
                    </Typography>

                    {/* Button */}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#33ada3", // Change to the desired color
                        padding: "10px 20px", // Padding adjustments
                        textTransform: "none", // Prevent text transformation
                        fontWeight: "bold", // Bold text
                      }}
                      href="/contact" // Replace with the desired link
                    >
                      &gt; Naar contactformulier
                    </Button>
                  </Grid>

                  {/* Right Side: Image */}
                  <Grid item xs={12} md={6}>
                    <Box
                      component="img"
                      src={contactUs}
                      alt="Person working on laptop"
                      sx={{
                        width: "100%",
                        borderRadius: 4,
                      }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Container>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Blog;
