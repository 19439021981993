import React, { Component } from "react";
import SessionManager from "../Authentication/SessionManager/session_manager";
import MachineCard from "./MachineCard";
import Historian from "./Historian";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

// Import SVG's
import statusSVG from "../../assets/icons/status.svg";
import pressureSVG from "../../assets/icons/druk.svg";
import tempSVG from "../../assets/icons/temperatuur.svg";
import soundSVG from "../../assets/icons/geluid.svg";
import humidSVG from "../../assets/icons/vochtigheid.svg";
import vibrationSVG from "../../assets/icons/trilling.svg";
import energySVG from "../../assets/icons/energie.svg";

import datafanImage from "../../assets/images/datafan/2023-12-Logo-Datafan.png";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://datafan.nl/">
        Datafan.nl
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      buttonBorders: ["none", "none", "none", "none", "none", "none", "none"],
      filterType: 0,
      visual: "0",
      searchQuery: "",
      isDrawerOpen: false,
      isSelectingMachines: false,
      selectedMachines: Array.from({ length: 120 }, (_, i) => (i + 1).toString()),
    };

    this.resetVisual = this.resetVisual.bind(this);
  }

  componentDidMount() {
    SessionManager.setupActivityTracking();
    this.performButtonPress(7);
  }

  componentWillUnmount() {
    SessionManager.removeActivityTracking();
  }

  componentDidUpdate() {
    SessionManager.handleUserActivity();
  }

  performButtonPress = (index) => {
    const updatedBorders = this.state.buttonBorders.map(() => "none");
    updatedBorders[index] = "3px solid #31aca3";
    this.setState({
      buttonBorders: updatedBorders,
      filterType: index,
    });
  };

  resetVisual() {
    this.setState({
      visual: "0",
    });
  }

  goToHistorian = (machineNumber) => {
    window.location.href = `/cockpit/historian/${machineNumber}`;
  };

  handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/authentication";
  };

  toggleDrawer = (open) => {
    this.setState({ isDrawerOpen: open });
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleMachineSelect = (event, newValue) => {
    this.setState({ selectedMachines: newValue });
  };

  toggleMachineSelection = () => {
    this.setState((prevState) => ({ isSelectingMachines: !prevState.isSelectingMachines }));
  };

  selectAllMachines = () => {
    this.setState({ selectedMachines: Array.from({ length: 120 }, (_, i) => (i + 1).toString()) });
  };

  deselectAllMachines = () => {
    this.setState({ selectedMachines: [] });
  };

  renderCards() {
    const machineNumbers = Array.from({ length: 120 }, (_, i) => (i + 1).toString());
    const customer = "Cockpit";

    return (
      <div style={{ padding: "0 10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "2%",
          }}
        >
          <IconButton onClick={() => this.toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <div style={{ fontSize: 30, fontWeight: "bolder" }}>Hoofdscherm - {customer}</div>
          <img src={datafanImage} alt="Datafan Logo" style={{ width: "100px" }} />
        </div>
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#32aca2", color: "white" }}
            onClick={this.toggleMachineSelection}
          >
            {this.state.isSelectingMachines ? "Klaar met selecteren" : "Selecteer Machines"}
          </Button>
        </div>
        {this.state.isSelectingMachines && (
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={this.selectAllMachines}
              style={{ marginRight: "10px" }}
            >
              Selecteer Alles
            </Button>
            <Button variant="contained" onClick={this.deselectAllMachines}>
              Deselecteer Alles
            </Button>
            <div
              style={{
                marginTop: "20px",
                width: "80%",
                textAlign: "center",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginLeft: "10%",
              }}
            >
              <Autocomplete
                multiple
                options={machineNumbers}
                value={this.state.selectedMachines}
                onChange={this.handleMachineSelect}
                getOptionLabel={(option) => `Machine ${option}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Selecteer Machines"
                    placeholder="Zoek..."
                    fullWidth // Ensures the TextField takes the full width
                  />
                )}
                sx={{
                  width: "100%", // Set Autocomplete width to be 100%
                  "& .MuiAutocomplete-popupIndicator": {
                    color: "#000",
                  },
                  "& .MuiAutocomplete-option": {
                    color: "#000",
                    backgroundColor: "#fff",
                    "&[data-focus='true']": {
                      backgroundColor: "#e0e0e0", // Change the background when focused, optional
                    },
                    "&[aria-selected='true']": {
                      backgroundColor: "#b3b3b3", // Change the background when selected, optional
                    },
                  },
                  "& .MuiAutocomplete-listbox": {
                    backgroundColor: "#fff",
                  },
                  "& .MuiChip-root": {
                    backgroundColor: "#ffffff", // Set chip (tag) background to white
                    color: "#000000", // Set chip (tag) text color to black
                    border: "1px solid #000000", // Optional: add a border for better contrast
                  },
                  "& .MuiSvgIcon-root": {
                    color: "black!important", // Set the delete icon ('X') color to black
                    "&:hover": {
                      color: "black!important", // Ensure the delete icon remains black even on hover
                    },
                  },
                }}
              />
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {this.state.selectedMachines.map((machineNumber) => (
            <a href={`/cockpit/historian/${machineNumber}`} key={machineNumber}>
              <div
                style={{
                  marginBottom: "20px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              >
                <MachineCard machine_number={machineNumber} filter_type={this.state.filterType} />
              </div>
            </a>
          ))}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Copyright />
        </div>
        <Drawer
          anchor="right"
          open={this.state.isDrawerOpen}
          onClose={() => this.toggleDrawer(false)}
        >
          <div
            style={{
              width: "250px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                height: "75%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7].map((index) => (
                <button
                  key={index}
                  style={{
                    border: this.state.buttonBorders[index],
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    margin: "10px 0",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.performButtonPress(index);
                    this.toggleDrawer(false);
                  }}
                >
                  <img
                    src={
                      index === 1
                        ? energySVG
                        : index === 2
                        ? pressureSVG
                        : index === 3
                        ? tempSVG
                        : index === 4
                        ? soundSVG
                        : index === 5
                        ? humidSVG
                        : index === 6
                        ? vibrationSVG
                        : statusSVG
                    }
                    alt={`SVG ${index}`}
                    width="30"
                    height="30"
                    style={{ display: "block", margin: "auto" }}
                  />
                </button>
              ))}
            </div>
            <button
              onClick={this.handleLogout}
              style={{
                border: "none",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                backgroundColor: "rgba(0, 0, 0, 0)",
                cursor: "pointer",
              }}
            >
              <ExitToAppIcon style={{ fontSize: 30 }} />
            </button>
          </div>
        </Drawer>
      </div>
    );
  }

  renderHistorian() {
    return (
      <div>
        <IconButton
          style={{ position: "absolute", top: "1%", left: "1%" }}
          onClick={this.resetVisual}
        >
          <ArrowBackIcon />
        </IconButton>
        {this.state.visual && <Historian machine_number={this.state.visual} />}
      </div>
    );
  }

  render() {
    return this.state.visual === "0" ? this.renderCards() : this.renderHistorian();
  }
}

export default DashboardContainer;
