import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

// Import the SVG files
import statusSVG from "../../../assets/icons/status.svg";
import pressureSVG from "../../../assets/icons/druk.svg";
import tempSVG from "../../../assets/icons/temperatuur.svg";
import soundSVG from "../../../assets/icons/geluid.svg";
import humidSVG from "../../../assets/icons/vochtigheid.svg";
import vibrationSVG from "../../../assets/icons/trilling.svg";
import energySVG from "../../../assets/icons/energie.svg";
import emergencySVG from "../../../assets/icons/emergency.svg";

import GetData from "../Scripts/get_card_data";

const useStyles = makeStyles({
  halfCircle: {
    width: "90px",
    height: "45px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderBottomLeftRadius: "45px",
    borderBottomRightRadius: "45px",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    borderLeft: "2px solid black",
    borderRight: "2px solid black",
    borderTop: "none",
    borderBottom: "2px solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "content-box",
    position: "absolute",
    top: 36,
    left: 13,
  },
  svgColorGoodDatafan: {
    filter:
      "invert(55%) sepia(63%) saturate(419%) hue-rotate(126deg) brightness(92%) contrast(91%)",
  },
  svgColorBadDatafan: {
    filter:
      "invert(63%) sepia(67%) saturate(1906%) hue-rotate(338deg) brightness(106%) contrast(100%)",
  },
  svgColorWarningDatafan: {
    filter:
      "invert(41%) sepia(76%) saturate(2354%) hue-rotate(339deg) brightness(97%) contrast(87%)",
  },
});

const MachineCard = ({ machine_number, filter_type }) => {
  const [machineData, setMachineData] = useState({});
  const [iconColorType, setIconColorType] = useState([1, 1, 1, 1, 1, 1, 1]);
  const classes = useStyles();

  const statusColorGood = "#32aca2";
  const statusColorWarn = "#ff9130";
  const statusColorBad = "#e7553e";

  const svgGood = classes.svgColorGoodDatafan;
  const svgWarn = classes.svgColorWarningDatafan;
  const svgBad = classes.svgColorBadDatafan;

  let statusColor = statusColorGood;
  let statusColorSVG = svgGood;
  const status = iconColorType[filter_type - 1];

  let visualTitle = "Loading...";
  let visual_value = "Loading...";
  let visual_prefix = "";

  switch (status) {
    case 1:
      statusColor = statusColorGood;
      statusColorSVG = svgGood;
      break;
    case 2:
      statusColor = statusColorWarn;
      statusColorSVG = svgWarn;
      break;
    case 3:
      statusColor = statusColorBad;
      statusColorSVG = svgBad;
      break;
    default:
      statusColor = statusColorGood;
      statusColorSVG = svgGood;
  }

  let visual_style = {
    position: "absolute",
    top: 0,
    right: 22,
    marginRight: 3,
    color: statusColor,
    fontSize: 22,
    fontWeight: "bold",
  };

  switch (filter_type) {
    case 1:
      visualTitle = "Energie";
      visual_value = machineData?.current?.additionalValue || "Loading...";
      visual_prefix = machineData?.current?.dataPrefix || "";
      break;
    case 2:
      visualTitle = "Luchtdruk";
      visual_value = machineData?.pressure?.additionalValue || "Loading...";
      visual_prefix = machineData?.pressure?.dataPrefix || "";
      visual_style = { ...visual_style, fontSize: 15 };
      break;
    case 3:
      visualTitle = "Temperatuur";
      visual_value = machineData?.temperature?.additionalValue || "Loading...";
      visual_prefix = machineData?.temperature?.dataPrefix || "";
      break;
    case 4:
      visualTitle = "Geluid";
      visual_value = machineData?.audio?.additionalValue || "Loading...";
      visual_prefix = machineData?.audio?.dataPrefix || "";
      visual_style = { ...visual_style, fontSize: 15 };
      break;
    case 5:
      visualTitle = "Luchtvochtigheid";
      visual_value = machineData?.humidity?.additionalValue || "Loading...";
      visual_prefix = machineData?.humidity?.dataPrefix || "";
      visual_style = { ...visual_style, fontSize: 20 };
      break;
    case 6:
      visualTitle = "Trilling";
      visual_value = machineData?.vibration?.additionalValue || "Loading...";
      visual_prefix = machineData?.vibration?.dataPrefix || "";
      visual_style = { ...visual_style, fontSize: 20 };
      break;
    case 7:
      visualTitle = "Gezondheid";
      visual_value = machineData?.status?.additionalValue || "Loading...";
      visual_prefix = "%";
      break;
  }

  const getIcon = (type) => {
    switch (type) {
      case 1:
        return energySVG;
      case 2:
        return pressureSVG;
      case 3:
        return tempSVG;
      case 4:
        return soundSVG;
      case 5:
        return humidSVG;
      case 6:
        return vibrationSVG;
      case 7:
        return statusSVG;
    }
  };

  const getColor = (type) => {
    return iconColorType[type - 1] === 1
      ? classes.svgColorGoodDatafan
      : iconColorType[type - 1] === 2
      ? classes.svgColorWarningDatafan
      : classes.svgColorBadDatafan;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const dataTest = await GetData.getCardData(machine_number);
        if (!dataTest) return;

        const data = {
          value: dataTest.machine_nr || "Loading...",
          audio: { additionalValue: dataTest.audio || "Loading...", dataPrefix: "Hz" },
          current: { additionalValue: dataTest.current || "Loading...", dataPrefix: "A" },
          humidity: { additionalValue: dataTest.humidity || "Loading...", dataPrefix: "%" },
          pressure: { additionalValue: dataTest.pressure_hpa || "Loading...", dataPrefix: "hPa" },
          temperature: {
            additionalValue: dataTest.temperature_c || "Loading...",
            dataPrefix: "°C",
          },
          vibration: {
            additionalValue: dataTest.vibration_severity || "Loading...",
            dataPrefix: "%",
          },
          status: { additionalValue: dataTest.status || "Loading..." },
        };

        const StatusList = await GetData.CalculateStatusList(data);
        setIconColorType(StatusList);
        setMachineData(data);
      } catch (error) {
        setMachineData({});
      }
    }

    fetchData();
    const interval = setInterval(fetchData, 30 * 60 * 1000);

    return () => clearInterval(interval);
  }, [machine_number]);

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: 225,
        height: 135,
      }}
    >
      <CardContent style={{ flex: 1 }}>
        <Typography
          style={{
            position: "absolute",
            marginBottom: 9,
            fontSize: 10,
            left: 13,
            fontWeight: "bold",
          }}
        >
          {visualTitle}
        </Typography>
        {status === 3 && (
          <Typography>
            <img
              src={emergencySVG}
              alt="Status SVG"
              width="36"
              height="36"
              style={{ position: "absolute", left: 104, top: 0 }}
            />
          </Typography>
        )}
        <Typography variant="subtitle1" style={visual_style}>
          {visual_value}
          {visual_prefix}
        </Typography>
        <div
          style={{
            position: "absolute",
            top: 36,
            right: 22,
            marginRight: 9,
            marginTop: 9,
            fontSize: 31,
            fontWeight: "bold",
          }}
        >
          <img
            src={getIcon(filter_type)}
            alt="Status SVG"
            width="36"
            height="36"
            className={statusColorSVG}
          />
        </div>
        <div className={classes.halfCircle} style={{ borderColor: statusColor }}>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 18, fontSize: 31, fontWeight: "bold" }}
          >
            {machineData.value || machine_number}
          </Typography>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 41,
            left: 13,
            right: 13,
            borderBottom: `2px solid ${statusColor}`,
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 4,
          }}
        >
          {[1, 2, 3, 4, 5, 6].map((type) => (
            <img
              key={type}
              src={getIcon(type)}
              alt="Status SVG"
              width="27"
              height="27"
              style={{ margin: "0 4px" }}
              className={getColor(type)}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

MachineCard.propTypes = {
  machine_number: PropTypes.string,
  filter_type: PropTypes.number,
};

export default MachineCard;
