import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import intakeImage from "../../../assets/images/intake.png";
import sensingImage from "../../../assets/images/sensing en onderzoek.png";
import storageImage from "../../../assets/images/storage oplossing.png";
import analysisImage from "../../../assets/images/analyse modelling.png";
import monitoringImage from "../../../assets/images/actieve monitoring.png";
import contactUs from "../../../assets/images/contactus.jpg";
import Footer from "../Footer";
import Header from "../Header";

// Create a global MUI theme with standardized font sizes
const theme = createTheme({
  typography: {
    h4: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.5rem", // Increased font size for step titles
      fontWeight: 600,
    },
    hSteps: {
      fontSize: "2rem", // Increased font size for step titles
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem", // Increased font size for step descriptions
      lineHeight: 1.6,
      color: "#333",
    },
    body2: {
      fontSize: "1.5rem", // Increased font size for step descriptions
      lineHeight: 1.6,
      color: "#333",
    },
    button: {
      textTransform: "none",
    },
  },
});

const HowItWorks = () => {
  // Scroll to the section with the given id
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Box sx={{ backgroundColor: "#ffffff", paddingTop: 10 }}>
          <Container maxWidth="md">
            {/* Top Section: Title and Steps */}
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
            >
              Onze werkwijze
            </Typography>

            {/* Teal line under the title */}
            <Box
              sx={{
                width: "80px",
                height: "4px",
                backgroundColor: "#33ada3",
                margin: "16px auto 24px auto",
              }}
            />

            <Typography
              variant="body2"
              align="center"
              sx={{
                fontSize: "1.25rem",
                lineHeight: "1.6",
                maxWidth: "60%",
                marginX: "auto",
                marginBottom: 4,
                color: "#333",
              }}
            >
              Bij Datafan werken we nauw samen met onze klanten om data-gedreven oplossingen te
              creëren die de prestaties van hun machines optimaliseren. Onze aanpak is
              gestructureerd en bestaat uit vijf stappen: Intake, Sensing Onderzoek & Uitvoering
            </Typography>

            {/* Blue Section with Steps */}
            <Box
              sx={{
                backgroundColor: "#143d5e",
                borderRadius: "30px",
                padding: "16px 32px",
                textAlign: "center",
                marginBottom: 4,
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap", // Allows wrapping if the screen size is small
                maxWidth: { xs: "100%", md: "80%" },
                mx: "auto", // Center the box
              }}
            >
              {[
                { label: "Stap 1: Intake", id: "step1" },
                { label: "Stap 2: Sensing Onderzoek & Uitvoering", id: "step2" },
                { label: "Stap 3: Storage Oplossing", id: "step3" },
                { label: "Stap 4: Data-analyse & Modellering", id: "step4" },
                { label: "Stap 5: Actieve monitoring", id: "step5" },
              ].map((step, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    padding: "8px 12px",
                    textDecoration: "underline",
                    cursor: "pointer", // Indicate that this is clickable
                  }}
                  onClick={() => scrollToSection(step.id)} // Scroll to section on click
                >
                  {step.label}
                </Typography>
              ))}
            </Box>

            {/* Step 1: Intake */}
            <Grid container spacing={4} sx={{ marginBottom: 6 }} alignItems="center" id="step1">
              <Grid item xs={12} md={4}>
                <Box
                  component="img"
                  src={intakeImage}
                  alt="Intake"
                  sx={{
                    width: "100%", // Image takes 1/3rd of the space
                    height: { xs: "auto", md: "auto" },
                    objectFit: "contain",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="hSteps"
                  sx={{ fontWeight: 700, marginBottom: 2, color: "#33ada3" }}
                >
                  Stap 1: Intake
                </Typography>
                <Typography variant="body2">
                  In de eerste stap voeren we een uitgebreide intake uit. Hierbij bepalen we de
                  operatie en cruciale informatie om te verzamelen over welke data van belang is.
                  Dit helpt ons om een goed beeld te krijgen van de behoeften en omstandigheden.
                </Typography>
              </Grid>
            </Grid>

            {/* Thicker Teal Divider */}
            <Divider sx={{ borderColor: "#33ada3", marginBottom: 6, borderWidth: "3px" }} />

            {/* Step 2: Sensing Onderzoek & Uitvoering */}
            <Grid container spacing={4} sx={{ marginBottom: 6 }} alignItems="center" id="step2">
              <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                <Typography
                  variant="hSteps"
                  sx={{ fontWeight: 700, marginBottom: 2, color: "#33ada3" }}
                >
                  Stap 2: Sensing Onderzoek & Uitvoering
                </Typography>
                <Typography variant="body2">
                  Na de intake starten we met ons sensing onderzoek. We bepalen welke sensoren het
                  meest geschikt zijn en wat hun toegevoegde waarde is voor de processen. Dit leidt
                  vaak tot een Proof of Concept (POC) om te testen.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                <Box
                  component="img"
                  src={sensingImage}
                  alt="Sensing Onderzoek"
                  sx={{
                    width: "100%", // Image takes 1/3rd of the space
                    height: { xs: "auto", md: "auto" },
                    objectFit: "contain",
                  }}
                />
              </Grid>
            </Grid>

            {/* Thicker Teal Divider */}
            <Divider sx={{ borderColor: "#33ada3", marginBottom: 6, borderWidth: "3px" }} />

            {/* Step 3: Storage Oplossing */}
            <Grid container spacing={4} sx={{ marginBottom: 6 }} alignItems="center" id="step3">
              <Grid item xs={12} md={4}>
                <Box
                  component="img"
                  src={storageImage}
                  alt="Storage Oplossing"
                  sx={{
                    width: "100%", // Image takes 1/3rd of the space
                    height: { xs: "auto", md: "auto" },
                    objectFit: "contain",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="hSteps"
                  sx={{ fontWeight: 700, marginBottom: 2, color: "#33ada3" }}
                >
                  Stap 3: Storage Oplossing
                </Typography>
                <Typography variant="body2">
                  In deze fase onderzoeken we de beste opslagoplossingen. Dit kan lokaal of in de
                  cloud zijn, afhankelijk van de behoeften van het project.
                </Typography>
              </Grid>
            </Grid>

            {/* Thicker Teal Divider */}
            <Divider sx={{ borderColor: "#33ada3", marginBottom: 6, borderWidth: "3px" }} />

            {/* Step 4: Data-analyse & Modellering */}
            <Grid container spacing={4} sx={{ marginBottom: 6 }} alignItems="center" id="step4">
              <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                <Typography
                  variant="hSteps"
                  sx={{ fontWeight: 700, marginBottom: 2, color: "#33ada3" }}
                >
                  Stap 4: Data-analyse & Modellering
                </Typography>
                <Typography variant="body2">
                  In deze fase richten we ons op de kwaliteit van de data en onderzoeken we de
                  actieve procesdata. We identificeren relevante problemen en analyseren de data met
                  behulp van AI-modellen.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                <Box
                  component="img"
                  src={analysisImage}
                  alt="Data-analyse"
                  sx={{
                    width: "100%", // Image takes 1/3rd of the space
                    height: { xs: "auto", md: "auto" },
                    objectFit: "contain",
                  }}
                />
              </Grid>
            </Grid>

            {/* Thicker Teal Divider */}
            <Divider sx={{ borderColor: "#33ada3", marginBottom: 6, borderWidth: "3px" }} />

            {/* Step 5: Actieve Monitoring */}
            <Grid container spacing={4} sx={{ marginBottom: 6 }} alignItems="center" id="step5">
              <Grid item xs={12} md={4}>
                <Box
                  component="img"
                  src={monitoringImage}
                  alt="Actieve Monitoring"
                  sx={{
                    width: "100%", // Image takes 1/3rd of the space
                    height: { xs: "auto", md: "auto" },
                    objectFit: "contain",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="hSteps"
                  sx={{ fontWeight: 700, marginBottom: 2, color: "#33ada3" }}
                >
                  Stap 5: Actieve Monitoring
                </Typography>
                <Typography variant="body2">
                  Tijdens deze fase wordt de analyse continu uitgevoerd en bieden we real-time
                  inzicht in het proces om snel te kunnen bijsturen wanneer nodig.
                </Typography>
              </Grid>
            </Grid>

            {/* Call to Action Section */}
            <Box
              sx={{
                backgroundColor: "#6BD1CA",
                borderRadius: 4,
                paddingY: 6,
                paddingX: 4,
                marginTop: 8,
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
                width: "100vw",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center">
                  {/* Left Side: Text and Button */}
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", marginBottom: 2, color: "#000" }}
                    >
                      Heb je een vraag of een uitdaging voor ons?
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 4, color: "#333" }}>
                      Wij staan voor je klaar! Neem gerust contact op.
                    </Typography>

                    {/* Button */}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#33ada3", // Change to the desired color
                        padding: "10px 20px", // Padding adjustments
                        textTransform: "none", // Prevent text transformation
                        fontWeight: "bold", // Bold text
                      }}
                      href="/contact" // Replace with the desired link
                    >
                      &gt; Naar contactformulier
                    </Button>
                  </Grid>

                  {/* Right Side: Image */}
                  <Grid item xs={12} md={6}>
                    <Box
                      component="img"
                      src={contactUs}
                      alt="Person working on laptop"
                      sx={{
                        width: "100%",
                        borderRadius: 4,
                      }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Container>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default HowItWorks;
