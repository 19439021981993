import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom"; // Import Link from React Router
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon
import datafanImage from "../../../assets/images/datafan/2023-12-Logo-Datafan.png";

// Create a custom theme (if you want to customize it further)
const theme = createTheme({
  typography: {
    button: {
      textTransform: "none", // To remove uppercase transformation
    },
  },
});

// Mapping of menu items and corresponding URLs
const menuItems = [
  { text: "Home", url: "/home" },
  { text: "Diensten", url: "/diensten" },
  { text: "Werkwijze", url: "/werkwijze" },
  { text: "Workshop", url: "/workshop" },
  { text: "Updates", url: "/updates" },
  { text: "Over ons", url: "/over-ons" },
];

export default function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Toggle Drawer Open/Close
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  // Drawer Menu for mobile with contact and login buttons
  const drawerMenu = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
        <IconButton onClick={toggleDrawer(false)} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Box>
      <List sx={{ magin: "auto" }}>
        {menuItems.map(({ text, url }) => (
          <ListItem button component={Link} to={url} key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Button
          component={Link}
          to="/contact" // Link to /contact
          sx={{
            backgroundColor: "#173F61",
            color: "white",
            borderRadius: 1,
            textTransform: "none",
            "&:hover": { backgroundColor: "#135C8A" }, // Darker on hover
          }}
        >
          Contact
        </Button>
        <Button
          component={Link}
          to="/cockpit" // Link to /cockpit
          variant="outlined"
          sx={{
            color: "#173F61",
            borderColor: "#173F61",
            borderRadius: 1,
            textTransform: "none",
          }}
        >
          Inloggen
        </Button>
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="fixed" // Fix the header to the top of the page
        color="transparent"
        elevation={0}
        sx={{ backgroundColor: "#f4f6f8", zIndex: 999 }} // Higher z-index to stay on top
      >
        <Toolbar
          sx={{
            justifyContent: "space-between", // Space between logo and the rest
            alignItems: "center", // Align items vertically center
          }}
        >
          {/* Logo Box */}
          <Box sx={{ flexGrow: 0 }}>
            <img
              src={datafanImage}
              alt="Logo"
              style={{ height: "40px", marginRight: theme.spacing(2) }} // Direct inline styling
            />
          </Box>

          {/* Hamburger Menu for Mobile */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            {/* Drawer anchored to the right */}
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawerMenu}
            </Drawer>
          </Box>

          {/* Navigation Links for Desktop */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" }, // Hidden on mobile, visible on desktop
              justifyContent: "center", // Center align the tabs horizontally
              flexGrow: 1, // Grow to take up available space
              gap: 4,
              paddingLeft: 2, // Add padding to move the tabs slightly to the right
            }}
          >
            {menuItems.map(({ text, url }) => (
              <Button
                key={text}
                component={Link}
                to={url} // Link to respective URL
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  textTransform: "none",
                  position: "relative",
                  backgroundColor: "transparent", // Remove gray background
                  "&:hover": {
                    backgroundColor: "transparent", // Ensure no hover background
                  },
                  "&:hover::after": {
                    content: '""',
                    position: "absolute",
                    width: "100%",
                    height: "2px",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#173F61", // Underline color
                    visibility: "visible",
                    transform: "scaleX(1)",
                    transition: "all 0.3s ease-in-out",
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    width: "100%",
                    height: "2px",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#173F61",
                    visibility: "hidden",
                    transform: "scaleX(0)",
                    transition: "all 0.3s ease-in-out",
                  },
                }}
              >
                {text}
              </Button>
            ))}
          </Box>

          {/* Contact and Login Box for Desktop */}
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            <Button
              component={Link}
              to="/contact" // Link to /contact
              sx={{
                backgroundColor: "#173F61", // Dark blue background
                color: "white",
                borderRadius: 1,
                textTransform: "none",
                "&:hover": { backgroundColor: "#135C8A" }, // Darker on hover
              }}
            >
              Contact
            </Button>
            <Button
              component={Link}
              to="/cockpit" // Link to /cockpit
              variant="outlined"
              sx={{
                color: "#173F61",
                borderColor: "#173F61",
                borderRadius: 1,
                textTransform: "none",
              }}
            >
              Inloggen
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
