/**
 * Function to parse the machine_factory_identifier and extract the location and machine type.
 * @param {string} identifier - The machine factory identifier in the specified format.
 * @param {string} user - The user parameter to be used in the API request.
 * @returns {Promise<{ location: string, machineType: string }>} Parsed values for location and machineType.
 */

import { API_KEY } from "./config";

const sentContactEmail = async (name, email, phone, message, subject) => {
  if (name) {
    const url =
      `https://datafan-login-api.azurewebsites.net/api/datafan-website-pages-api?` +
      `code=${encodeURIComponent(API_KEY)}&` +
      `name=${encodeURIComponent(name)}&` +
      `type=${encodeURIComponent("contact")}&` +
      `email=${encodeURIComponent(email)}&` +
      `phone=${encodeURIComponent(phone)}&` +
      `message=${encodeURIComponent(message)}&` +
      `subject=${encodeURIComponent(subject)}`;

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return true; // Read response as text
      })
      .catch((error) => {
        // Handle errors here
        throw error; // re-throw the error to propagate it to the next catch block
      });
  }
  return { success: false }; // If no username provided, return false
};

export default sentContactEmail;
