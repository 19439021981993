import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate, useParams } from "react-router-dom";

const SuccessMessage = () => {
  const navigate = useNavigate();
  const { machine_factory_identifier } = useParams();

  const handleNewMessageClick = () => {
    // Reload the same page for the same device identifier
    navigate(`/cockpit-input/${machine_factory_identifier}`);
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Checkmark Icon */}
        <CheckCircleOutlineIcon sx={{ fontSize: "4rem", color: "#32aca2" }} />

        {/* Success Text */}
        <Typography
          component="h1"
          variant="h5"
          sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
        >
          Bericht succesvol opgeslagen!
        </Typography>

        {/* Button to add a new message */}
        <Button
          variant="contained"
          onClick={handleNewMessageClick}
          sx={{
            backgroundColor: "#32aca2",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#289a91",
            },
          }}
        >
          Voeg een nieuw bericht toe
        </Button>
      </Box>
    </Container>
  );
};

export default SuccessMessage;
