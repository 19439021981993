import CryptoJS from "crypto-js";

class SessionManager {
  static inactivityTimeout;

  static handleUserActivity() {
    SessionManager.checkActiveSession();
    clearTimeout(SessionManager.inactivityTimeout); // Clear previous timeout
    SessionManager.inactivityTimeout = setTimeout(() => {
      // Clear session token from local storage
      console.log("Session Expired");
      // this.endSession();
      // window.location.href = "/authentication";
    }, 60 * 60 * 1000); // 60 minutes timeout (adjust as needed)
  }

  static checkActiveSession() {
    const token = localStorage.getItem("token");
    const company_key = localStorage.getItem("company_key");
    const company_extra_key = localStorage.getItem("company_extra_key");

    if (token == null) {
      this.endSession();
      window.location.href = "/authentication";
      return null;
    }

    if (company_key == null) {
      this.endSession();
      window.location.href = "/authentication";
      return null;
    }

    if (company_extra_key == null) {
      this.endSession();
      window.location.href = "/authentication";
      return null;
    }

    // decrypt keys
    const company_key_decrypted = this.getCompanyApiKey(company_key);

    if (!this.getCompanyEncyptionKey(company_key_decrypted)) {
      this.endSession();
      window.location.href = "/authentication";
    }
  }

  static endSession() {
    console.log("Session Expired");
    localStorage.removeItem("token");
    localStorage.removeItem("company_key");
    localStorage.removeItem("company_extra_key");
    localStorage.removeItem("authentication_request_status");

    return 0;
  }

  static setupActivityTracking() {
    window.addEventListener("mousemove", SessionManager.handleUserActivity);
    window.addEventListener("keydown", SessionManager.handleUserActivity);
  }

  static removeActivityTracking() {
    window.removeEventListener("mousemove", SessionManager.handleUserActivity);
    window.removeEventListener("keydown", SessionManager.handleUserActivity);
  }

  static startSesion(api_key, email, database) {
    const token = this.createToken(api_key, email, database);
    localStorage.setItem("token", token);

    const encrypted_api_key = this.encryptCompanyApiKey(api_key);
    localStorage.setItem("company_key", encrypted_api_key);

    const encrypted_database = this.encryptDatabase(database);
    localStorage.setItem("company_extra_key", encrypted_database);

    localStorage.removeItem("authentication_request_status");
  }

  static createToken(api_key, email, database) {
    const combined = api_key + ":" + email;

    const encryption_key = database;

    return CryptoJS.AES.encrypt(combined, encryption_key).toString();
  }

  static encryptCompanyApiKey(api_key) {
    return CryptoJS.AES.encrypt(api_key, process.env.REACT_APP_COMPANY_KEY_CODE_SESSION);
  }

  static encryptDatabase(database) {
    return CryptoJS.AES.encrypt(database, process.env.REACT_APP_DATABASE_CODE_SESSION).toString();
  }

  static decryptDatabase() {
    const company_extra_key = localStorage.getItem("company_extra_key");
    const decryptedBytes = CryptoJS.AES.decrypt(
      company_extra_key,
      process.env.REACT_APP_DATABASE_CODE_SESSION
    );
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8); // Convert WordArray to Utf8 string
    return decryptedData;
  }

  static getCompanyApiKey(api_key_encrypted) {
    const decryptedBytes = CryptoJS.AES.decrypt(
      api_key_encrypted,
      process.env.REACT_APP_COMPANY_KEY_CODE_SESSION
    );
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8); // Convert WordArray to Utf8 string
    return decryptedData;
  }

  static decryptToken(token, api_key_encrypted) {
    const company_key_decrypted = this.getCompanyApiKey(api_key_encrypted);

    const decrypt_key = this.getCompanyEncyptionKey(company_key_decrypted);

    return CryptoJS.AES.decrypt(token, decrypt_key);
  }

  static apiKeyValidationCheck(company_key_decrypted) {
    const validkeys = process.env.REACT_APP_VALID_DATAFAN_API_KEYS.split(",");

    return validkeys.includes(company_key_decrypted);
  }

  static getCompanyEncyptionKey(company_key_decrypted) {
    if (this.apiKeyValidationCheck(company_key_decrypted)) {
      return this.decryptDatabase();
    } else {
      return this.endSession();
    }
  }

  static getLoggedInUser() {
    const token = localStorage.getItem("token");
    const company_key = localStorage.getItem("company_key");

    if (!token || !company_key) return null;

    const decryptedToken = this.decryptToken(token, company_key);
    const userData = decryptedToken.toString(CryptoJS.enc.Utf8).split(":");

    // Assuming the format is api_key + email, extract the email as the user identifier
    return {
      email: userData[1] || "Unknown User",
    };
  }
}

export default SessionManager;
