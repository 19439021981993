import SessionManager from "../../Authentication/SessionManager/session_manager";
import { API_KEY } from "./config";

class GetData {
  static getCardData(machine_number) {
    const company_key = this.getCompanyKey();
    const database_key = SessionManager.decryptDatabase();

    const url =
      `https://datafan-website-dashboards.azurewebsites.net/api/datafan_website_data_api?` +
      `code=${encodeURIComponent(API_KEY)}&` +
      `api_key=${encodeURIComponent(company_key)}&` +
      `database_id=${encodeURIComponent(database_key)}&` +
      `machine_number=${encodeURIComponent(machine_number)}`;

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          console.log(401);
        }
        return response.json();
      })
      .catch((error) => {
        // Handle errors here
        console.error(error); // re-throw the error to propagate it to the next catch block
      });
  }

  static getCompanyKey() {
    const company_key_encrypted = localStorage.getItem("company_key");
    return SessionManager.getCompanyApiKey(company_key_encrypted);
  }

  static async CalculateStatusList(data) {
    try {
      let StatusList = [1, 1, 1, 1, 1, 1, 1];

      // Current
      //// Warning
      if (data.current.status == "warning") {
        StatusList[0] = 2;
      }
      //// Bad
      if (data.current.status == "error") {
        StatusList[0] = 3;
      }

      // Pressure
      //// Warning
      if (data.pressure.status == "warning") {
        StatusList[1] = 2;
      }
      //// Bad
      if (data.pressure.status == "error") {
        StatusList[1] = 3;
      }

      // Temperature
      //// Warning
      if (data.temperature.status == "warning") {
        StatusList[2] = 2;
      }
      //// Bad
      if (data.temperature.status == "error") {
        StatusList[2] = 3;
      }

      // Sound
      //// Warning
      if (data.audio.status == "warning") {
        StatusList[3] = 2;
      }
      //// Bad
      if (data.audio.status == "error") {
        StatusList[3] = 3;
      }

      // Humidity
      //// Warning
      if (data.humidity.status == "warning") {
        StatusList[4] = 2;
      }
      //// Bad
      if (data.humidity.status == "error") {
        StatusList[4] = 3;
      }

      // Vibration
      //// Warning
      if (data.vibration.additionalValue > 40) {
        StatusList[5] = 2;
      }

      //// Bad
      if (data.vibration.additionalValue > 70) {
        StatusList[5] = 3;
      }

      //// Warning
      if (data.status.additionalValue < 60) {
        StatusList[6] = 2;
      }
      //// Bad
      if (data.status.additionalValue < 35) {
        StatusList[6] = 3;
      }

      return StatusList;
    } catch (error) {
      console.error(error);
    }
  }

  static GetLimits(machine_number) {
    const company_key = this.getCompanyKey();
    const database_key = SessionManager.decryptDatabase();

    const url =
      `https://datafan-website-dashboards.azurewebsites.net/api/datafan_website_data_api?` +
      `code=${encodeURIComponent(API_KEY)}&` +
      `api_key=${encodeURIComponent(company_key)}&` +
      `database_id=${encodeURIComponent(database_key)}&` +
      `machine_number=${encodeURIComponent(machine_number)}&` +
      `type=${encodeURIComponent("limits")}`;

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          console.log(401);
        }
        return response.json();
      })
      .catch((error) => {
        // Handle errors here
        console.error(error); // re-throw the error to propagate it to the next catch block
      });
  }
}

export default GetData;
