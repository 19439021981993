import React, { useState, useEffect } from "react";
import Login from "./Login";
import TwoFactor from "./twofactor";

function Authentication() {
  const [authStatus, setAuthStatus] = useState(
    localStorage.getItem("authentication_request_status")
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setAuthStatus(localStorage.getItem("authentication_request_status"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return authStatus === "true";
}

export default function AuthenticationContainer() {
  const isAuthenticated = Authentication();

  return isAuthenticated ? <TwoFactor /> : <Login />;
}
