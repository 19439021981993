import React from "react";
import { Grid, Typography, Card, CardContent, Container, Box, Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import HomeHeader from "./HomeHeader";
import Header from "../Header";
import Footer from "../Footer";
import ourTeamImage from "../../../assets/images/our-team-image.png";
import InnovationIcon from "../../../assets/images/ICOON Innovatie.png";
import EfficiencyIcon from "../../../assets/images/ICOON Efficientie.png";
import CustomerMindedIcon from "../../../assets/images/ICOON Klantgericht.png";
import TrustworthyIcon from "../../../assets/images/ICOON Betrouwbaar.png";

// Create the MUI theme
const theme = createTheme({
  typography: {
    button: {
      textTransform: "none", // Prevent buttons from being uppercase
    },
  },
});

const Home = () => {
  return (
    <ThemeProvider theme={theme}>
      {/* The fixed Header */}
      <Header />

      {/* HomeHeader and main content */}
      <Box sx={{ height: "100vh", paddingTop: "64px" }}>
        <HomeHeader />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          zIndex: 2,
          position: "relative",
          backgroundColor: "#ffffff", // White background to create the plane effect
          borderTopLeftRadius: 40, // Rounding for the top corners
          borderTopRightRadius: 40,
        }}
      >
        {/* Main Content */}
        <Container maxWidth="lg">
          {/* Grid with text on the left and stacked cards on the right */}
          <Grid container spacing={4} alignItems="center" sx={{ marginTop: 20 }}>
            {/* Text Section (Left Side), vertically centered */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center", // Center vertically
                height: "100%", // Full height to align with cards
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 2 }}>
                Uw partner in data voor industrie en techniek
              </Typography>
              <Typography variant="body1">
                Bij Datafan focussen we ons op bedrijven die dagelijks werken met machines. Ons doel
                is om deze bedrijven te helpen hun processen te optimaliseren en daarbij hun
                prestaties te verbeteren.
              </Typography>
            </Grid>

            {/* Cards Section (Right Side, stacked vertically) */}
            <Grid item xs={12} md={6}>
              <Box>
                {/* Card 1 */}
                <Card
                  sx={{
                    backgroundColor: "#33ada3", // Teal background color
                    borderRadius: 2,
                    padding: 3,
                    color: "#ffffff", // White text
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      De maakindustrie
                    </Typography>
                    <Typography variant="body2">
                      Data oplossingen voor productiebedrijven.
                    </Typography>
                  </CardContent>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "#ffffff",
                      color: "#ffffff",
                      borderRadius: 2,
                      minWidth: "40px",
                      height: "40px",
                    }}
                    href="/diensten#maak-industrie"
                  >
                    &gt;
                  </Button>
                </Card>

                {/* Card 2 */}
                <Card
                  sx={{
                    backgroundColor: "#143d5e", // Dark blue background color
                    borderRadius: 2,
                    padding: 3,
                    color: "#ffffff", // White text
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      Onderhouds- en installatiebedrijven
                    </Typography>
                    <Typography variant="body2">
                      Dataoplossingen voor onderhouds- en installatiebedrijven
                    </Typography>
                  </CardContent>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "#ffffff",
                      color: "#ffffff",
                      borderRadius: 2,
                      minWidth: "40px",
                      height: "40px",
                    }}
                    href="/diensten#service-en-onderhoud"
                  >
                    &gt;
                  </Button>
                </Card>
              </Box>
            </Grid>
          </Grid>

          {/* Promise Section */}
          <Box sx={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
            <Box
              sx={{
                maxWidth: "60%", // Make the content 60% of the container width
                width: "100%", // Ensure full width responsiveness
                margin: "0 auto", // Center the content
              }}
            >
              {/* Small Title Above the Quote */}
              <Typography
                variant="subtitle1"
                sx={{ color: "#143d5e", fontWeight: "bold", marginBottom: 1 }}
              >
                De Datafan belofte
              </Typography>

              {/* The Quote */}
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#143d5e", marginBottom: 2 }}
              >
                &quot;We helpen je de toekomstige prestaties van je machinepark te verbeteren.&quot;
              </Typography>

              {/* Colored Underline */}
              <Box
                sx={{
                  width: "80px",
                  height: "4px",
                  backgroundColor: "#33ada3",
                  margin: "0 auto",
                }}
              />
            </Box>
          </Box>

          {/* Core Values Section */}
          <Box sx={{ textAlign: "center", marginTop: 8, marginBottom: 8 }}>
            {/* Title */}
            <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 2 }}>
              Onze kernwaarden
            </Typography>

            {/* Subtitle */}
            <Typography variant="body1" sx={{ marginBottom: 4 }}>
              Dankzij het gebruik van baanbrekende technologieën blijven we voortdurend innoveren om
              <br />
              onze klanten de meest geavanceerde oplossingen te bieden.
              <br />
              Lees meer over&nbsp;
              <a href="/over-ons">
                <u>onze kernwaarden bij Over ons.</u>
              </a>
            </Typography>

            {/* Core Values Grid */}
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#143d5e", // Dark blue background
                    borderRadius: 4,
                    padding: 3,
                    textAlign: "center",
                    color: "#ffffff", // White text and icons
                  }}
                >
                  {/* Icon */}
                  <Box component="span" sx={{ fontSize: 48, display: "block", marginBottom: 2 }}>
                    <Box
                      component="img"
                      src={InnovationIcon} // Replace with actual image source
                      alt="Innovation Icon"
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Innovatie
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#143d5e",
                    borderRadius: 4,
                    padding: 3,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  {/* Icon */}
                  <Box component="span" sx={{ fontSize: 48, display: "block", marginBottom: 2 }}>
                    <Box
                      component="img"
                      src={EfficiencyIcon} // Replace with actual image source
                      alt="Innovation Icon"
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Efficiëntie
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#143d5e",
                    borderRadius: 4,
                    padding: 3,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  {/* Icon */}
                  <Box component="span" sx={{ fontSize: 48, display: "block", marginBottom: 2 }}>
                    <Box
                      component="img"
                      src={CustomerMindedIcon} // Replace with actual image source
                      alt="Innovation Icon"
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Klantgericht
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#143d5e",
                    borderRadius: 4,
                    padding: 3,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                >
                  {/* Icon */}
                  <Box component="span" sx={{ fontSize: 48, display: "block", marginBottom: 2 }}>
                    <Box
                      component="img"
                      src={TrustworthyIcon} // Replace with actual image source
                      alt="Innovation Icon"
                    />
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Betrouwbaar
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Datafan Way Section */}
            <Box sx={{ textAlign: "center", marginTop: 8, marginBottom: 8 }}>
              {/* Top Teal Line */}
              <Box
                sx={{
                  width: "100%",
                  height: "2px",
                  backgroundColor: "#33ada3",
                  marginBottom: 2,
                }}
              />

              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Dit is onze Datafan manier
                </Typography>

                {/* Button */}
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "#000000",
                    color: "#000000",
                    paddingX: 4,
                    paddingY: 1.5,
                    borderRadius: 2,
                    fontWeight: "bold",
                  }}
                  href="/werkwijze"
                >
                  &gt; Meer informatie
                </Button>
              </Box>

              {/* Bottom Teal Line */}
              <Box
                sx={{
                  width: "100%",
                  height: "2px",
                  backgroundColor: "#33ada3",
                  marginTop: 2,
                }}
              />
            </Box>
          </Box>

          {/* Team Section */}
          <Box
            sx={{
              backgroundColor: "#6BD1CA", // Teal background
              borderRadius: 4,
              paddingY: 6,
              paddingX: 4,
              marginTop: 8,
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
              width: "100vw", // Make the section full width
              position: "relative", // Ensure it stays within the viewport width
              left: "50%", // Adjust alignment
              transform: "translateX(-50%)", // Center the Box horizontally
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={4} alignItems="center">
                {/* Left Side: Image */}
                <Grid item xs={12} md={6}>
                  <Box
                    component="img"
                    src={ourTeamImage} // Replace with the actual image path
                    alt="Team working"
                    sx={{
                      width: "100%",
                      borderRadius: 4, // Rounded corners for the image
                    }}
                  />
                </Grid>

                {/* Right Side: Text and Buttons */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 2 }}>
                    Ons team
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 4 }}>
                    Ons team van jonge en ervaren experts helpt je organisatie om data om te zetten
                    in waardevolle inzichten. Met een combinatie van technische expertise en
                    klantgerichtheid zorgen we voor oplossingen die jouw bedrijfsdoelen
                    ondersteunen. We werken nauw samen met onze klanten om maatwerkoplossingen te
                    bieden die écht impact maken.
                  </Typography>

                  {/* Buttons */}
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#33ada3",
                        color: "#ffffff",
                        paddingX: 3,
                        paddingY: 1.5,
                        borderRadius: 2,
                      }}
                      href="/over-ons"
                    >
                      &gt; Over ons
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#33ada3",
                        color: "#ffffff",
                        paddingX: 3,
                        paddingY: 1.5,
                        borderRadius: 2,
                      }}
                      href="/contact"
                    >
                      &gt; Contact
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Home;
