import React, { useState } from "react";
import {
  Box,
  Button,
  createTheme,
  TextField,
  ThemeProvider,
  Typography,
  Grid,
} from "@mui/material";
import Footer from "../Footer";
import Header from "../Header";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import sentContactEmail from "./send_email"; // Import the function

// Create a global MUI theme with standardized font sizes
const theme = createTheme({
  typography: {
    // Your theme settings...
  },
});

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Naam is verplicht.";
    if (!formData.email) {
      tempErrors.email = "E-mailadres is verplicht.";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      tempErrors.email = "Voer een geldig e-mailadres in.";
    }
    if (!formData.phone) {
      tempErrors.phone = "Telefoonnummer is verplicht.";
    } else if (!/^\+?[0-9]{10,15}$/.test(formData.phone)) {
      tempErrors.phone = "Voer een geldig telefoonnummer in.";
    }
    if (!formData.subject) tempErrors.subject = "Onderwerp is verplicht.";
    if (!formData.message) tempErrors.message = "Bericht is verplicht.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await sentContactEmail(
          formData.name,
          formData.email,
          formData.phone,
          formData.message,
          formData.subject
        );
        if (response) {
          setSubmissionStatus(
            "Bericht succesvol verzonden! We nemen zo spoedig mogelijk contact met je op."
          );
        } else {
          setSubmissionStatus(
            "Er is iets misgegaan bij het verzenden van het bericht. Probeer het opnieuw."
          );
        }
      } catch (error) {
        setSubmissionStatus(`Fout bij het verzenden: ${error.message}`);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Box sx={{ backgroundColor: "#ffffff", paddingTop: 10, paddingBottom: 10 }}>
          {/* Title Section */}
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
          >
            Contact
          </Typography>
          {/* Teal line under the title */}
          <Box
            sx={{
              width: "80px",
              height: "4px",
              backgroundColor: "#33ada3",
              margin: "16px auto 24px auto",
            }}
          />

          <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: 600, marginBottom: 2, color: "#333" }}
          >
            Wij horen graag van je!
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{
              fontSize: "1.25rem",
              lineHeight: "1.6",
              maxWidth: "45%",
              marginX: "auto",
              marginBottom: 4,
              color: "#333",
            }}
          >
            Voor vragen en opmerkingen kun je onderstaand formulier invullen. We nemen zo snel
            mogelijk contact met je op.
          </Typography>

          {/* Contact Form Section */}
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "600px",
              marginX: "auto",
              padding: 4,
              backgroundColor: "#ffffff",
              borderRadius: "16px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Naam *"
                  name="name"
                  variant="outlined"
                  fullWidth
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="E-mailadres *"
                  name="email"
                  variant="outlined"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Telefoonnummer *"
                  name="phone"
                  variant="outlined"
                  fullWidth
                  value={formData.phone}
                  onChange={handleChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Onderwerp *"
                  name="subject"
                  variant="outlined"
                  fullWidth
                  value={formData.subject}
                  onChange={handleChange}
                  error={!!errors.subject}
                  helperText={errors.subject}
                  sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bericht *"
                  name="message"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                  sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#33ada3",
                "&:hover": { backgroundColor: "#2b928b" },
                textTransform: "none",
                borderRadius: "8px",
                paddingX: 4,
                paddingY: 1.5,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              Verstuur bericht
            </Button>
            {submissionStatus && (
              <Typography
                variant="body1"
                align="center"
                sx={{ color: submissionStatus.includes("succes") ? "green" : "red", marginTop: 2 }}
              >
                {submissionStatus}
              </Typography>
            )}
          </Box>

          {/* Visit Address Section */}
          <Box
            sx={{
              marginTop: 8,
              textAlign: "left",
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, marginBottom: 2, color: "#333", textAlign: "center" }}
            >
              Bezoekadres
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                marginBottom: 4,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocationOnIcon sx={{ color: "#33ada3", marginRight: 1 }} />
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Datafan
                  </Typography>
                  <Typography variant="body2">Parallelweg 30</Typography>
                  <Typography variant="body2">5223 AL &apos;s-Hertogenbosch</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PhoneIcon sx={{ color: "#33ada3", marginRight: 1 }} />
                <Typography variant="body1">+31 (0)85 876 9971</Typography>
              </Box>
            </Box>
            {/* Google Maps Integration */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48732.89054000863!2d4.76338579140637!3d52.379189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5eddfdd4b56c1%3A0x54f4d8e43f8b8e4e!2sParallelweg%2030%2C%205223%20AL%20's-Hertogenbosch%2C%20Netherlands!5e0!3m2!1sen!2sus!4v1683225407220!5m2!1sen!2sus"
                width="600"
                height="450"
                style={{ border: 0, borderRadius: "16px" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Contact;
