import SessionManager from "../../Authentication/SessionManager/session_manager";
import { API_KEY } from "./config";

/**
 * Function to process and handle form data.
 * Sends the form data to the server along with additional parameters.
 * @param {Object} formData - The form data to be processed.
 * @param {string} machineFactoryIdentifier - The machine factory identifier.
 * @param {string} user - The user parameter to be used in the API request.
 */
const processFormData = async (formData, machineFactoryIdentifier, user) => {
  const company_key_encrypted = localStorage.getItem("company_key");
  const company_key = SessionManager.getCompanyApiKey(company_key_encrypted);
  const database_key = SessionManager.decryptDatabase();

  // Construct the API URL with separate parameters for each form field
  const url =
    `https://datafan-website-dashboards.azurewebsites.net/api/datafan_website_operator_module?` +
    `code=${encodeURIComponent(API_KEY)}&` +
    `api_key=${encodeURIComponent(company_key)}&` +
    `user=${encodeURIComponent(user)}&` +
    `identifier=${encodeURIComponent(machineFactoryIdentifier)}&` +
    `database_id=${encodeURIComponent(database_key)}&` +
    `task=${encodeURIComponent(formData.task)}&` +
    `shift=${encodeURIComponent(formData.shift)}&` +
    `taskCategory=${encodeURIComponent(formData.taskCategory)}&` +
    `operatorName=${encodeURIComponent(formData.operatorName)}`;

  try {
    // Send the data to the server using a GET request
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Check if the response is OK
    if (!response.ok) {
      console.error(`Error: ${response.status}`);
      return;
    }

    // Log the response for debugging purposes
    const results = await response.json();
    console.log("Server response:", results);
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export default processFormData;
