const postMapping = [
  {
    key: "3dprinting",
    title: "Haal meer uit je 3D-printer ",
    date: "vrijdag 16 augustus 2024",
    readTime: "2 minuten leestijd",
    author: "Noah Lammertsma",
    excerpt:
      "De opkomst van 3D-printing heeft de potentie om de industrie opnieuw te transformeren. Wat ooit werd gezien als een technologie van de toekomst...",
    image: require("../../../assets/images/3D printer.jpg"),
    link: "/updates/3dprinting",
    introContent: `
        Bij Datafan werken we nauw samen met onze klanten om datagedreven oplossingen te creëren die de prestaties van hun machines optimaliseren. Onze aanpak is gestructureerd en bestaat uit vijf stappen.
      `,
    sectionsBeforeImage: [
      {
        title: "Introductie",
        content: `
              De opkomst van 3D-printing heeft de potentie om de industrie opnieuw te transformeren. Wat ooit werd gezien als een technologie van de toekomst, wordt nu steeds meer een realiteit. Van prototypes tot complexe industriële onderdelen, 3D-printing biedt een ongekende flexibiliteit en efficiëntie.   

              Toch is er één grote uitdaging: de betrouwbaarheid en efficiëntie van het printproces. Dit is waar Datafan in beeld komt. Met onze geavanceerde data-oplossingen helpen we bedrijven om hun 3D-printers slimmer en effectiever in te zetten, waardoor de productiviteit stijgt en verspilling wordt geminimaliseerd.
            `,
      },
      {
        title: "Het belang van OEE (Overall Equipment Efficiency)  ",
        content: `
              OEE, ofwel Overall Equipment Efficiency, is een essentiële maatstaf voor de efficiëntie van productiemachines. Het geeft aan hoeveel procent van de geproduceerde producten in één keer goed is. Voor standaard 3D-printers ligt dit percentage vaak rond de 25%. Dit betekent dat 75% van de tijd en materialen verloren gaan door fouten of suboptimale prestaties. Voor veel bedrijven is dit een grote drempel om 3D-printing breed in te zetten. Om de werkelijke potentie van 3D-printing te benutten, moet de OEE aanzienlijk omhoog. Datafan helpt bedrijven deze uitdaging aan te gaan door waardevolle inzichten te bieden die de efficiëntie van 3D-printers verhogen.  
            `,
      },
    ],
    sectionsAfterImage: [
      {
        title: "Ontwikkeling sensing strategie",
        content: `
            Om de prestaties van je 3D-printer inzichtelijk te maken, ontwikkelt Datafan een op maat gemaakte sensing strategie. We verzamelen data rechtstreeks van de printer en koppelen deze aan de gegevens uit de 3D-bestanden van de geprinte objecten. Dit geeft ons een compleet beeld van zowel het productieproces als het eindproduct. Door de juiste sensoren in te zetten en de relevante data vast te leggen, kunnen we precies achterhalen waar in het proces optimalisaties mogelijk zijn. Zo maakt Datafan de 3D-printer niet alleen inzichtelijk, maar ook voorspelbaar en betrouwbaar.  
          `,
      },
      {
        title: "Inzicht via Dashboard",
        content: `
          Zodra de benodigde data verzameld is, zet Datafan deze om in bruikbare inzichten. We creëren dashboards met actuele informatie over de prestaties van je 3D-printer, inclusief de geschiedenis van eerdere prints en trends in de kwaliteit van geprinte producten. Daarnaast passen we machine learning toe om patronen te herkennen en proactief problemen te signaleren. Dit stelt ons in staat om de OEE te verbeteren door het aantal mislukte prints te verminderen, fouten vroegtijdig te identificeren en de kwaliteit van het eindproduct te waarborgen. Uiteindelijk bespaar je hiermee tijd, materiaal en kosten.      
        `,
      },
      {
        title: "Kortom ",
        content: `
            Het verhogen van de OEE is cruciaal voor het optimaal benutten van 3D-printers in industriële omgevingen. Datafan biedt oplossingen die je helpen om het meeste uit je 3D-printer te halen. Door middel van dataverzameling, slimme analyses en machine learning zorgen we ervoor dat de processen efficiënter worden, zodat je meer kunt produceren met minder middelen. Met onze aanpak bespaar je tijd, reduceer je het materiaalverbruik en verlaag je de kosten.  
        `,
      },
    ],
  },
  {
    key: "industrial_innovation",
    title: "Industrie 4.0: de volgende stap in Industriële Innovatie",
    date: "vrijdag 20 september 2024",
    readTime: "2 minuten leestijd",
    author: "Noah Lammertsma",
    excerpt:
      "In de afgelopen 250 jaar hebben we verschillende industriële revoluties doorgemaakt. Het begon allemaal met de eerste industriële revolutie in 1764, toen de stoommachine werd geïntroduceerd...",
    image: require("../../../assets/images/industry-4-0.jpg"),
    link: "/updates/industrial_innovation",
    introContent: `
        Bij Datafan werken we nauw samen met onze klanten om datagedreven oplossingen te creëren die de prestaties van hun machines optimaliseren. Onze aanpak is gestructureerd en bestaat uit vijf stappen.
      `,
    sectionsBeforeImage: [
      {
        title: "Wat is industrie 4.0?",
        content: `
            In de afgelopen 250 jaar hebben we verschillende industriële revoluties doorgemaakt. Het begon allemaal met de eerste industriële revolutie in 1764, toen de stoommachine werd geïntroduceerd. Deze technologie leidde tot de opkomst van de maakindustrie.
          `,
      },
      {
        title: "Industrie 2.0",
        content: `
            De tweede industriële revolutie volgde in 1867 en bracht een overgang naar elektriciteit en staal als drijvende krachten achter de industriële vooruitgang, waardoor de afhankelijkheid van stoomtechnologie werd verminderd.
          `,
      },
      {
        title: "Industrie 3.0",
        content: `
            De innovaties die voortkwamen uit deze revoluties leidden tot de derde industriële revolutie rond 1950, bekend als de automatiseringsrevolutie. In deze periode werden machines intelligenter en verschaften technologieën zoals computers en robots een nieuwe dimensie aan de industrie.
          `,
      },
    ],
    sectionsAfterImage: [
      {
        title: "De volgende stap",
        content: `
            Vandaag de dag staan we aan de vooravond van de vierde industriële revolutie, ook wel Industrie 4.0 genoemd. Deze revolutie draait om de integratie van data en technologie in de productieprocessen.
  
            Centrale vragen in deze nieuwe fase zijn:
            - Hoe kunnen we op de meest efficiënte manier data verzamelen?
            - Wat kan data betekenen voor onze productie?
            - Waar kunnen we kosten besparen op onze machines?
            - Hoe verbeteren we onze concurrentiepositie?
          `,
      },
      {
        title: "Data-analyse en connectiviteit",
        content: `
            Industrie 4.0, of de 'connected factory', legt de nadruk op het gebruik van geavanceerde data-analysetechnieken en connectiviteit om productieprocessen te optimaliseren, kosten te verlagen en de concurrentiepositie te versterken. Deze vierde revolutie markeert een nieuwe stap in de voortdurende evolutie van industriële technologieën.
          `,
      },
      {
        title: "Industrie 4.0 en Datafan",
        content: `
            Zoals eerder beschreven, is het cruciaal voor bedrijven om de overgang te maken van automatisering naar connectiviteit. Maar hoe doe je dat? Daar komt Datafan om de hoek kijken. Met onze expertise in Industrie 4.0 ondersteunen we u bij elke stap in dit proces, van sensing en data-opslag tot data-analyse en visualisatie.
          `,
      },
    ],
  },
  {
    key: "preventive_and_predictive_maintenance",
    title: "Verschil: preventief en voorspellend onderhoud",
    date: "Vrijdag 18 oktober 2024",
    readTime: "2 minuten leestijd",
    author: "Noah Lammertsma",
    excerpt:
      "Onderhoud van machines en apparatuur is essentieel voor de continuïteit en efficiëntie van productieprocessen...",
    image: require("../../../assets/images/service1.png"),
    link: "/updates/preventive_and_predictive_maintenance",
    introContent: `
        Onderhoud van machines en apparatuur is essentieel voor de continuïteit en efficiëntie van productieprocessen. Twee veelgebruikte strategieën zijn preventief en voorspellend onderhoud. Hoewel beide benaderingen gericht zijn op het minimaliseren van uitvaltijd en reparatiekosten, verschillen ze aanzienlijk in hun aanpak en toepassing. 
      `,
    sectionsBeforeImage: [
      {
        title: "Preventief Onderhoud",
        content: `
            Preventief onderhoud is een geplande benadering waarbij onderhoudswerkzaamheden worden uitgevoerd op vaste intervallen, ongeacht de actuele toestand van de apparatuur. Dit type onderhoud is gebaseerd op historische gegevens en fabrikantaanbevelingen. De kern van preventief onderhoud is het uitvoeren van regelmatige inspecties, vervangingen en reparaties om onverwachte storingen te voorkomen. 
            `,
      },
      {
        title: "Voordelen van preventief onderhoud:",
        content: `
            - Voorspelbaarheid: Door onderhoud op vaste tijdstippen uit te voeren, is de planning eenvoudig en overzichtelijk. 
            - Vermindering van onverwachte uitvaltijd: Regelmatige controles helpen bij het identificeren en oplossen van potentiële problemen voordat ze tot storingen leiden. 
            - Verlengen van de levensduur van apparatuur: Consistente zorg en onderhoud kunnen de levensduur van machines verlengen. 
          `,
      },
      {
        title: "Nadelen van preventief onderhoud:",
        content: `
            - Kosten: Regelmatig onderhoud kan leiden tot hogere kosten, vooral als het onderhoud onnodig is voor goed functionerende apparatuur. 
            - Niet altijd efficiënt: Niet alle componenten hebben dezelfde slijtage- of falingspatronen, waardoor sommige onderhoudsactiviteiten overbodig kunnen zijn.     
        `,
      },
    ],
    sectionsAfterImage: [
      {
        title: "Voorspellend Onderhoud ",
        content: `
            Voorspellend onderhoud, ook wel condition-based maintenance (CBM) genoemd, maakt gebruik van real-time gegevens en geavanceerde analysemodellen om de toestand van apparatuur continu te monitoren en onderhoud uit te voeren wanneer dat nodig is. Dit type onderhoud is afhankelijk van sensoren en dataverzameling om afwijkingen en slijtagepatronen te detecteren voordat er daadwerkelijk een storing optreedt. 
          `,
      },
      {
        title: "Voordelen van voorspellend onderhoud:",
        content: `
            - Efficiëntie: Onderhoud wordt alleen uitgevoerd wanneer het nodig is, wat leidt tot een optimale inzet van middelen en kostenbesparingen. 
            - Minder ongeplande stilstand: Door afwijkingen vroegtijdig te detecteren, kunnen potentiële storingen voorkomen worden voordat ze optreden. 
            - Verlengde levensduur: Doordat onderhoud precies op het juiste moment wordt uitgevoerd, kunnen machines langer meegaan en blijven ze in betere conditie. 
            - Data-gedreven inzichten: Voorspellend onderhoud biedt waardevolle data die kunnen bijdragen aan het verbeteren van de operationele processen en besluitvorming.     
        `,
      },
      {
        title: "Nadelen van voorspellend onderhoud:",
        content: `
            - Initiële investeringen: Het opzetten van een voorspellend onderhoudssysteem vereist aanzienlijke investeringen in sensortechnologie, dataverwerkingscapaciteit en analyse-algoritmen. 
            - Complexiteit: Het implementeren en onderhouden van deze systemen kan complex zijn en gespecialiseerde kennis vereisen. 
            - Afhankelijkheid van technologie: Storingen in het monitorsysteem kunnen leiden tot onverwachte problemen en uitvaltijd. 
        `,
      },
      {
        title: "Conclusie",
        content: `
            Preventief en voorspellend onderhoud zijn beide waardevolle strategieën voor het beheren van apparatuur en het minimaliseren van storingen. Preventief onderhoud is eenvoudig te plannen en biedt voorspelbaarheid, maar kan inefficiënt en kostbaar zijn door onnodige onderhoudswerkzaamheden. Voorspellend onderhoud daarentegen biedt efficiëntie en kostenbesparingen door onderhoud precies op het juiste moment uit te voeren, maar vereist hogere initiële investeringen en gespecialiseerde kennis. Door de juiste onderhoudsstrategie te kiezen, kunnen bedrijven de prestaties van hun machines optimaliseren en de operationele efficiëntie verbeteren. 
        `,
      },
    ],
  },
];

export default postMapping;
