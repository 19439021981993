import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import Footer from "../Footer";
import Header from "../Header";
import contactUs from "../../../assets/images/contactus.jpg";
import workshopImage from "../../../assets/images/workshop.jpg"; // Add the new workshop image
import sentContactEmail from "./send_email"; // Import the function

// Create a global MUI theme with standardized font sizes
const theme = createTheme({
  typography: {
    h4: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.5rem", // Increased font size for step titles
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem", // Increased font size for step descriptions
      lineHeight: 1.6,
      color: "#333",
    },
    body2: {
      fontSize: "0.9rem", // Font size for additional information
      lineHeight: 1.4,
      color: "#333",
    },
    button: {
      textTransform: "none",
    },
  },
});

const Workshop = () => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
  });
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Reset error messages on input change
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^\+?[0-9]{10,15}$/; // Simple phone validation regex (international format)
    return regex.test(phone);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {};

    // Basic validation
    if (!formData.name) {
      newErrors.name = "Naam is verplicht.";
    }
    if (!formData.company) {
      newErrors.company = "Bedrijfsnaam is verplicht.";
    }
    if (!formData.email) {
      newErrors.email = "E-mail is verplicht.";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Voer een geldig e-mailadres in.";
    }
    if (!formData.phone) {
      newErrors.phone = "Telefoonnummer is verplicht.";
    } else if (!validatePhone(formData.phone)) {
      newErrors.phone = "Voer een geldig telefoonnummer in.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Stop submission if there are errors
    }

    try {
      const response = await sentContactEmail(
        formData.name,
        formData.email,
        formData.phone,
        formData.company
      );
      if (response) {
        setConfirmationMessage(
          "Aanmelding succesvol verzonden! We nemen zo spoedig mogelijk contact met je op."
        );
      } else {
        setConfirmationMessage(
          "Er is iets misgegaan bij het verzenden van het aanmelding. Probeer het opnieuw."
        );
      }
    } catch (error) {
      setConfirmationMessage(`Fout bij het verzenden: ${error.message}`);
    }

    // Reset form fields
    setFormData({
      name: "",
      company: "",
      email: "",
      phone: "",
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Box sx={{ backgroundColor: "#ffffff", paddingTop: 10 }}>
        {/* New Workshop Title and Description */}
        <Box sx={{ maxWidth: "80%", mx: "auto", mb: 4 }}>
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
          >
            Workshop: Verbeteren machineprestaties met AI
          </Typography>

          {/* Teal line under the title */}
          <Box
            sx={{
              width: "80px",
              height: "4px",
              backgroundColor: "#33ada3",
              margin: "16px auto 24px auto",
            }}
          />

          <Typography
            variant="body1"
            sx={{ textAlign: "center", marginTop: 2, maxWidth: "65%", mx: "auto" }}
          >
            De workshop spitst zich toe op nieuwe, innovatieve ontwikkelingen in de industrie. We
            staan namelijk aan de vooravond van de volgende industriële revolutie. Deze draait om de
            integratie van data en technologie. Data-analyse en connectiviteit zullen
            productieprocessen gaan optimaliseren. Hoe dit gaat werken wordt uit de doeken gedaan
            door Robert Wit en Noah Lammertsma van Datafan.
          </Typography>
        </Box>

        <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            component="img"
            src={workshopImage}
            alt="Workshop"
            sx={{
              width: "100%",
              maxWidth: "800px",
              height: "400px",
              objectFit: "cover",
              borderRadius: "10px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Grid>

        {/* New Section: Industrie 4.0 */}
        <Box sx={{ maxWidth: "80%", mx: "auto", mb: 4, paddingTop: 5 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#143d5e",
              marginBottom: 2,
              maxWidth: "65%",
              mx: "auto",
            }}
          >
            Introductie industrie 4.0
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2, maxWidth: "65%", mx: "auto" }}>
            In het programma van 2 uur word je allereerst bijgepraat over&nbsp;
            <a href="/updates/industrial_innovation">
              <u>Industrie 4.0</u>
            </a>
            &nbsp;en het gebruik van AI.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#143d5e",
              marginBottom: 2,
              maxWidth: "65%",
              mx: "auto",
            }}
          >
            Presentatie voorbeelden
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2, maxWidth: "65%", mx: "auto" }}>
            Een aantal bedrijven zijn al bezig met het gebruik van AI. We gaan in op een aantal
            praktijktoepassingen om je te inspireren en om te laten zien dat AI niet per se complex
            is.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#143d5e",
              marginBottom: 2,
              maxWidth: "65%",
              mx: "auto",
            }}
          >
            Impact eigen bedrijf
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2, maxWidth: "65%", mx: "auto" }}>
            Hierna ga je met behulp van deze informatie of verkenning naar de mogelijkheden die
            aanwezig zijn in je eigen bedrijf. Je kijkt met andere ogen naar je eigen
            productieprocessen; je leert om praktisch en vernieuwend hierover na te denken.
          </Typography>

          {/* Table for Duration and Activities */}
          <TableContainer
            component={Paper}
            sx={{
              marginTop: 4,
              maxWidth: "50%",
              mx: "auto",
              backgroundColor: "#6BD1CA",
              color: "#fff",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "#143d5e", fontSize: "140%" }}>
                    Activiteit
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#143d5e", fontSize: "140%" }}>
                    Duur
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Ontvangst</TableCell>
                  <TableCell>15 minuten</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Introductie Industrie 4.0 en toelichting voorbeelden</TableCell>
                  <TableCell>45 minuten</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Verkenning impact voor je bedrijf</TableCell>
                  <TableCell>1 uur en 15 minuten</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: "#143d5e", marginTop: 4, maxWidth: "65%", mx: "auto" }}
          >
            Informatie over Datafan
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: "65%", mx: "auto" }}>
            Datafan zorgt dat het MKB in staat is om machines optimaal te laten presteren in het
            productieproces door het gebruik van vernieuwende data. Dit gebeurt door het meten van
            relevante gegevens, het ontsluiten van deze data en het analyseren van patronen. Datafan
            gebruikt hiervoor baanbrekende technologieën en zorgt ervoor om altijd innovatief te
            zijn.
          </Typography>
        </Box>

        <Box sx={{ maxWidth: "80%", mx: "auto" }}>
          {" "}
          {/* Centering the box */}
          <Box
            sx={{
              width: "100%",
              height: "2px",
              backgroundColor: "#33ada3",
              marginBottom: 2,
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              maxWidth: "65%",
              mx: "auto",
            }}
          >
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Interesse om deel te nemen?
            </Typography>

            {/* Button to open modal */}
            <Button
              variant="outlined"
              onClick={() => setOpenModal(true)} // Open modal on click
              sx={{
                color: "#33ada3", // Set text color to teal
                paddingX: 4,
                paddingY: 1.5,
                borderRadius: 2,
                fontWeight: "bold",
              }}
            >
              &gt; Aanmelden workshop
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "2px",
              backgroundColor: "#33ada3",
              marginTop: 2,
            }}
          />
        </Box>

        {/* Modal for Registration Form */}
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Aanmelden voor de workshop</DialogTitle>
          <DialogContent>
            {confirmationMessage ? (
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                {confirmationMessage}
              </Typography>
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="name"
                  label="Naam"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={formData.name}
                  required
                  error={!!errors.name}
                  helperText={errors.name} // Display error message if exists
                />
                <TextField
                  margin="dense"
                  name="company"
                  label="Bedrijfsnaam"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={formData.company}
                  required
                  error={!!errors.company}
                  helperText={errors.company} // Display error message if exists
                />
                <TextField
                  margin="dense"
                  name="email"
                  label="E-mail"
                  type="email"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={formData.email}
                  required
                  error={!!errors.email}
                  helperText={errors.email} // Display error message if exists
                />
                <TextField
                  margin="dense"
                  name="phone"
                  label="Telefoonnummer"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={formData.phone}
                  required
                  error={!!errors.phone}
                  helperText={errors.phone} // Display error message if exists
                />
                <DialogActions>
                  <Button
                    onClick={() => setOpenModal(false)}
                    sx={{
                      borderColor: "#33ada3", // Set border color to teal
                      color: "#33ada3", // Set text color to teal
                      paddingX: 4,
                      paddingY: 1.5,
                      borderRadius: 2,
                      fontWeight: "bold",
                    }}
                  >
                    Annuleren
                  </Button>
                  <Button
                    type="submit"
                    sx={{
                      borderColor: "#33ada3", // Set border color to teal
                      color: "#33ada3", // Set text color to teal
                      paddingX: 4,
                      paddingY: 1.5,
                      borderRadius: 2,
                      fontWeight: "bold",
                    }}
                  >
                    Verzenden
                  </Button>
                </DialogActions>
              </form>
            )}
          </DialogContent>
        </Dialog>

        <Box
          sx={{
            backgroundColor: "#6BD1CA",
            borderRadius: 4,
            paddingY: 6,
            paddingX: 4,
            marginTop: 8,
            borderTopLeftRadius: "40px",
            borderTopRightRadius: "40px",
            width: "100vw",
            position: "relative",
            left: "65%",
            transform: "translateX(-65%)",
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              {/* Left Side: Text and Button */}
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", marginBottom: 2, color: "#000" }}
                >
                  Heb je een vraag of een uitdaging voor ons?
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 4, color: "#333" }}>
                  Wij staan voor je klaar! Neem gerust contact op.
                </Typography>

                {/* Button */}
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#33ada3", // Change to the desired color
                    padding: "10px 20px", // Padding adjustments
                    textTransform: "none", // Prevent text transformation
                    fontWeight: "bold", // Bold text
                  }}
                  href="/contact" // Replace with the desired link
                >
                  &gt; Naar contactformulier
                </Button>
              </Grid>

              {/* Right Side: Image */}
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={contactUs}
                  alt="Person working on laptop"
                  sx={{
                    width: "100%",
                    borderRadius: 4,
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export default Workshop;
