import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import serviceImage2 from "../../../assets/images/service1.png";
import serviceImage1 from "../../../assets/images/service2.png";
import contactUs from "../../../assets/images/contactus.jpg";
import Footer from "../Footer";
import Header from "../Header";

// Create a global MUI theme with standardized font sizes
const theme = createTheme({
  typography: {
    h4: {
      fontSize: "2rem", // Match the Home component h4 size
      fontWeight: 700, // Bold
    },
    h5: {
      fontSize: "1.5rem", // Smaller section headers
      fontWeight: 600, // Slightly less bold
    },
    body1: {
      fontSize: "1rem", // Standard body text size
      lineHeight: 1.6, // Consistent line-height for readability
      color: "#333", // Standard text color
    },
    button: {
      textTransform: "none", // Ensure buttons are not uppercase
    },
  },
});

const Services = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Box sx={{ backgroundColor: "#ffffff", paddingTop: 10 }}>
          <Container maxWidth="md">
            {/* Title Section */}
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
            >
              Onze diensten
            </Typography>

            {/* Teal line under the title */}
            <Box
              sx={{
                width: "80px",
                height: "4px",
                backgroundColor: "#33ada3",
                margin: "16px auto 24px auto",
              }}
            />

            <Typography
              variant="body1"
              align="center"
              sx={{
                fontSize: "1.25rem",
                lineHeight: "1.6",
                maxWidth: "60%",
                marginX: "auto",
                marginBottom: 4,
                color: "#333",
              }}
            >
              Bij Datafan richten we ons op bedrijven die dagelijks werken met machines. Ons doel is
              om deze bedrijven te helpen hun processen te optimaliseren en hun prestaties te
              verbeteren. We bieden onze diensten aan twee specifieke sectoren: de maakindustrie en
              onderhouds- en installatiebedrijven.
            </Typography>

            {/* First Image and Content Block */}
            <Grid container spacing={4} sx={{ marginBottom: 6 }} id="maak-industrie">
              <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  component="img"
                  src={serviceImage1}
                  alt="De Maakindustrie"
                  sx={{
                    width: "100%",
                    maxWidth: "800px",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={8} sx={{ marginX: "auto" }}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
                >
                  De maakindustrie
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Bedrijven in de maakindustrie vormen de ruggengraat van de productie-economie.
                  Deze bedrijven produceren fysieke goederen met behulp van geavanceerde machines en
                  industriële apparatuur. Efficiëntie is hier cruciaal: hoe sneller en
                  kosteneffectiever een bedrijf zijn producten kan maken, hoe groter zijn
                  concurrentievoordeel.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2, textAlign: "justify" }}>
                  Datafan helpt bedrijven in de maakindustrie niet alleen om hun productieprocessen
                  te optimaliseren, maar ook om onderhoudswerkzaamheden efficiënter te plannen. Met
                  behulp van data monitoren we continu de prestaties en identificeren we vroegtijdig
                  afwijkingen. Zo wordt ongeplande stilstand verminderd wat niet alleen de
                  efficiëntie verhoogd, maar ook de levensduur van uw machines verhoogd met minder
                  operationele kosten.
                </Typography>
              </Grid>
            </Grid>

            {/* Second Image and Content Block */}
            <Grid container spacing={4} sx={{ marginBottom: 6 }} id="service-en-onderhoud">
              <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  component="img"
                  src={serviceImage2}
                  alt="Onderhouds- en installatiebedrijven"
                  sx={{
                    width: "100%",
                    maxWidth: "800px",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8} sx={{ marginX: "auto" }}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
                >
                  Onderhouds- en installatiebedrijven
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Onderhouds- en installatiebedrijven spelen een essentiële rol in het draaiende
                  houden van machines en apparaten. Of het nu gaat om het oplossen van een storing
                  of om het uitvoeren van periodiek onderhoud, deze bedrijven zorgen ervoor dat
                  machines in topconditie blijven.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2, textAlign: "justify" }}>
                  Datafan ondersteunt onderhouds- en installatiebedrijven door hen te voorzien van
                  real-time gegevens en analyses die helpen bij het vroegtijdig signaleren van
                  problemen en het optimaal plannen van onderhoudswerkzaamheden. Dit leidt tot
                  onderhoud dat precies op tijd wordt uitgevoerd, minder onverwachte storingen, en
                  een efficiëntere aanpak.
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ marginBottom: 6 }} id="service-en-onderhoud">
              <Grid item xs={12} md={8} sx={{ marginX: "auto" }}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 700, marginBottom: 2, color: "#143d5e" }}
                >
                  Onze werkwijze
                </Typography>

                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Bij Datafan begrijpen we de unieke uitdagingen en behoeften van deze sectoren. Of
                  je nu actief bent in de maakindustrie of in de onderhouds- en installatiebranche,
                  wij staan klaar om je te helpen de bedrijfsprocessen te verbeteren en doelen te
                  bereiken. Dit doen we met&nbsp;
                  <a href="/werkwijze">
                    <u>onze eigen werkwijze.</u>
                  </a>
                </Typography>
              </Grid>
            </Grid>

            {/* Call to Action Section */}
            <Box
              sx={{
                backgroundColor: "#6BD1CA",
                borderRadius: 4,
                paddingY: 6,
                paddingX: 4,
                marginTop: 8,
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
                width: "100vw",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center">
                  {/* Left Side: Text and Button */}
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", marginBottom: 2, color: "#000" }}
                    >
                      Heb je een vraag of een uitdaging voor ons?
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 4, color: "#333" }}>
                      Wij staan voor je klaar! Neem gerust contact op.
                    </Typography>

                    {/* Button */}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#33ada3", // Change to the desired color
                        padding: "10px 20px", // Padding adjustments
                        textTransform: "none", // Prevent text transformation
                        fontWeight: "bold", // Bold text
                      }}
                      href="/contact" // Replace with the desired link
                    >
                      &gt; Naar contactformulier
                    </Button>
                  </Grid>

                  {/* Right Side: Image */}
                  <Grid item xs={12} md={6}>
                    <Box
                      component="img"
                      src={contactUs}
                      alt="Person working on laptop"
                      sx={{
                        width: "100%",
                        borderRadius: 4,
                      }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Container>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Services;
