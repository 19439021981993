import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import GetTasks from "./get_tasks";

export default function Uitgevoerd({ machineNumber }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [doorFilter, setDoorFilter] = useState("");
  const [actions, setActions] = useState([]); // State to store fetched actions
  const [uniqueDoors, setUniqueDoors] = useState([]); // State to store unique door values
  const [loading, setLoading] = useState(true); // Initial loading state
  const [updating, setUpdating] = useState(false); // State to track subsequent updates

  // Create a new instance of GetTasks using useRef to prevent re-instantiation on every render
  const taskInstanceRef = useRef(new GetTasks());

  // Use useEffect to fetch tasks when the component mounts or when the machine number changes
  useEffect(() => {
    fetchTasks(true); // Call fetchTasks function with `initialLoad` set to true for the first-time loading
  }, [machineNumber]); // Re-run the effect when machineNumber changes

  // Fetch tasks asynchronously
  const fetchTasks = async (initialLoad = false) => {
    try {
      if (initialLoad) {
        setLoading(true); // Show initial loading indicator only for the first load
      } else {
        setUpdating(true); // Use updating state for subsequent loads
      }

      // Fetch the tasks using the GetTasks instance
      const taskData = await taskInstanceRef.current.get_tasks(machineNumber, 7867);

      // Extract unique 'door' values from the fetched data
      const doors = Array.from(new Set(taskData.map((action) => action.door)));

      setActions(taskData); // Replace existing actions with the new ones
      setUniqueDoors(doors); // Update unique door values
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false); // Disable initial loading after the first fetch completes
      setUpdating(false); // Disable updating after subsequent fetch completes
    }
  };

  // Filtered actions based on the search term and door filter
  const filteredActions = actions.filter((action) => {
    const matchesSearch = action.actie.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDoor = !doorFilter || action.door === doorFilter;
    return matchesSearch && matchesDoor;
  });

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: "8px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sansSerif" }}
          gutterBottom
        >
          Uitgevoerd
        </Typography>

        {/* Search and Door Filter */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Zoek acties..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginRight: 2, width: "70%" }} // Adjust width as needed
          />
          <FormControl fullWidth size="small" sx={{ width: "70%" }}>
            <Select
              value={doorFilter}
              onChange={(e) => setDoorFilter(e.target.value)}
              displayEmpty
              input={<OutlinedInput />}
              sx={{ height: "40px" }} // Ensure the height matches the search bar
            >
              <MenuItem value="">All</MenuItem>
              {uniqueDoors.map((door) => (
                <MenuItem key={door} value={door}>
                  {door}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Divider />

      <Grid
        container
        spacing={2}
        sx={{ overflowY: "auto", flexGrow: 1, paddingRight: 1, maxHeight: "60vh" }}
      >
        {loading ? (
          // Show loading message only during the initial load
          <Typography sx={{ padding: 2 }}>Acties ophalen...</Typography>
        ) : filteredActions.length === 0 ? (
          // Show this message when there are no matching actions
          <Typography sx={{ padding: 2 }}>Geen uitgevoerde acties gevonden</Typography>
        ) : (
          // Render the filtered actions
          filteredActions.map((action, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: 2,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography variant="body2">
                  <b>Door:</b> {action.door}
                </Typography>
                <Typography variant="body2">
                  <b>Op:</b> {action.datum}
                </Typography>
                <Typography variant="body2">
                  <b>Taak:</b> <br /> {action.actie}
                </Typography>
              </Box>
            </Grid>
          ))
        )}
      </Grid>

      {/* Update indicator (shown only when fetching subsequent updates) */}
      {updating && (
        <Typography
          variant="caption"
          sx={{
            padding: 2,
            textAlign: "center",
            color: "gray",
            marginBottom: 2,
          }}
        >
          Bezig met het ophalen van nieuwe acties...
        </Typography>
      )}

      <Button
        variant="contained"
        sx={{ marginTop: 2, alignSelf: "center", backgroundColor: "#32aca2", color: "#fff" }}
        onClick={() => fetchTasks(false)} // Trigger an update manually when button is clicked
      >
        +
      </Button>
    </Box>
  );
}

// Add PropTypes validation for the component's props
Uitgevoerd.propTypes = {
  machineNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
