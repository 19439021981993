import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, CardMedia, Typography, Button, Box } from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material";

// Create a global MUI theme with standardized font sizes
const theme = createTheme({
  typography: {
    h4: {
      fontSize: "2rem", // Match the Home component h4 size
      fontWeight: 700, // Bold
    },
    h5: {
      fontSize: "1.5rem", // Smaller section headers
      fontWeight: 600, // Slightly less bold
    },
    body1: {
      fontSize: "1rem", // Standard body text size
      lineHeight: 1.6, // Consistent line-height for readability
      color: "#333", // Standard text color
    },
    button: {
      textTransform: "none", // Ensure buttons are not uppercase
    },
  },
  palette: {
    primary: {
      main: "#143d5e", // Updated primary color to match the provided color code
    },
  },
});

// Styled Components using MUI's styled API
const Container = styled(Box)({
  height: 550, // Slightly taller height for consistency
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: 16,
  padding: 16,
  maxWidth: 365,
  margin: "16px",
  verticalAlign: "top",
});

const StyledCard = styled(Card)({
  maxWidth: 345,
  borderRadius: 16,
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  textDecoration: "none", // Remove underline from the link
  color: "inherit", // Inherit text color for consistency
  "&:hover": {
    textDecoration: "none",
  },
});

const Media = styled(CardMedia)({
  height: 200, // Consistent image height across all cards
  width: "100%", // Ensure consistent width to make all images the same size
  objectFit: "cover", // Ensure the image fills the space while keeping aspect ratio, cutting off if necessary
  overflow: "hidden", // Ensure the image is cut off if it doesn't fit
});

const ContentContainer = styled(CardContent)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingBottom: "16px",
});

const Excerpt = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3, // Limit to 3 lines
  WebkitBoxOrient: "vertical",
  marginTop: "16px",
});

const BlogTemplateSmall = ({ postDetails }) => {
  if (!postDetails) {
    console.error("postDetails prop is undefined or null.");
  } else {
    console.log("Received postDetails:", postDetails);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <a href={postDetails.link || "#"} style={{ textDecoration: "none", color: "inherit" }}>
          <StyledCard>
            <Media
              component="img"
              image={postDetails?.image || "https://via.placeholder.com/400x200"} // Use image from postDetails
              alt={postDetails?.title || "Default Title"}
            />
            <ContentContainer>
              <Typography variant="h4" component="div" gutterBottom>
                {postDetails?.title || "Default Title"}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {postDetails?.date || "Unknown Date"} |{" "}
                {postDetails?.readTime || "Unknown Read Time"}
              </Typography>
              <Excerpt variant="body1" color="textPrimary">
                {postDetails?.excerpt || "Default excerpt content..."}
              </Excerpt>
              {postDetails?.link && (
                <Box display="flex" justifyContent="flex-start" marginTop="auto">
                  <Button
                    variant="text"
                    color="primary"
                    href={postDetails.link} // Link to full article
                    onClick={(e) => e.stopPropagation()} // Prevent the parent link from being triggered
                  >
                    {"> Lees meer"}
                  </Button>
                </Box>
              )}
            </ContentContainer>
          </StyledCard>
        </a>
      </Container>
    </ThemeProvider>
  );
};

BlogTemplateSmall.propTypes = {
  postDetails: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    readTime: PropTypes.string,
    excerpt: PropTypes.string,
    link: PropTypes.string,
  }),
};

BlogTemplateSmall.defaultProps = {
  postDetails: {
    image: "https://via.placeholder.com/400x200",
    title: "Default Title",
    date: "Unknown Date",
    readTime: "Unknown Read Time",
    excerpt: "Default excerpt content...",
    link: "#",
  },
};

export default BlogTemplateSmall;
