import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// images
import datafanImage from "../../../assets/images/datafan/2023-12-Logo-Datafan.png";

import Authenticate from "../Login/authenticate.js";
import SessionManager from "../SessionManager/session_manager.js";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://datafan.nl/">
        Datafan.nl
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#32aca2",
    },
  },
});

export default function TwoFactor() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null); // State to hold error message

  // const phone = "+316*****776";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    const code = data.get("code");

    try {
      const request_verification = await Authenticate.send_2fa_request(code);
      if (request_verification == 401) {
        setError("Incorrect verification code");
      } else {
        const decrypted_api_key = request_verification["api_key"];
        const decrypted_email = request_verification["email"];
        const decrypted_database = request_verification["database"];

        SessionManager.startSesion(decrypted_api_key, decrypted_email, decrypted_database);
        window.location.href = "/cockpit";
      }
      // } catch (error) {
      //   console.log(error);
      //   setError("Unexpected Error"); // Set error message for 401 status code
    } finally {
      setLoading(false);
    }
  };

  const cancelRequest = () => {
    localStorage.removeItem("authentication_request_status");
    window.location.href = "/authentication";
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="s">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "5vh",
          }}
        >
          {/* Use the imported image */}
          <img src={datafanImage} alt="Datafan Image" style={{ width: "20%" }} />
          <Avatar
            sx={{
              m: 1,
              bgcolor: "#32aca2",
              marginTop: "0px",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Twee Factor Authenticatie
          </Typography>
          <Box
            sx={{
              width: "15%",
              borderBottom: "2px solid #32aca2",
              my: 2,
              borderRadius: "15%",
            }}
          />
          <Typography component="p"> Er is een SMS verzonden naar uw telefoonnummer. </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              width: "40%",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="code"
              label="Verificatie Code"
              name="code"
              autoComplete="code"
              autoFocus
              sx={{
                "& label.Mui-focused": { color: "#32aca2" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": { borderColor: "#32aca2" },
                },
              }}
            />
            {error && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading} // Disable the button when loading
              sx={{ backgroundColor: "#32aca2", mt: 3, mb: 2, color: "white" }}
            >
              {loading ? "Loading..." : "Inloggen"} {/* Display "Loading..." when loading */}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  onClick={cancelRequest}
                  variant="body2"
                  sx={{ color: "#32aca2", cursor: "pointer" }}
                >
                  Annuleren
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
