import React from "react";
import { Box, Container, Grid, Typography, Link as MuiLink, Divider } from "@mui/material";
import { LinkedIn } from "@mui/icons-material"; // LinkedIn Icon
import datafanLogoWhite from "../../../assets/images/datafan-logo-white.png"; // Replace with your actual image import
import postMapping from "../Blog/postMapping"; // Import your postMapping
import brochurePDF from "../../../assets/Datafan-Algemeene-Voorwaarden.pdf"; // Adjust the path to your file

const Footer = () => {
  return (
    <>
      {/* Black section */}
      <Box sx={{ backgroundColor: "#1a1a1a", color: "#fff", paddingY: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="flex-start">
            {/* Left Column: Logo and Social */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center the logo and LinkedIn icon
                }}
              >
                <img
                  src={datafanLogoWhite} // Replace with your actual logo path
                  alt="Datafan Logo"
                  style={{ height: "80px" }}
                />
                <Divider sx={{ width: "50%", backgroundColor: "#fff", marginY: 2 }} />
                {/* Centered LinkedIn Icon as a Link */}
                <MuiLink
                  href="https://www.linkedin.com/company/datafan"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn sx={{ fontSize: 40, color: "#33ada3", marginBottom: "8px" }} />
                </MuiLink>
              </Box>
            </Grid>

            {/* Middle Column: Datafan and Diensten */}
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Box>
                  <Typography variant="h6" sx={{ marginBottom: 2, color: "#fff" }}>
                    Datafan
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#fff" }}>
                    <MuiLink href="/over-ons" color="inherit" underline="none">
                      Over ons
                    </MuiLink>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ marginBottom: 2, color: "#fff" }}>
                    Diensten
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#fff" }}>
                    <MuiLink href="/diensten" color="inherit" underline="none">
                      Werkwijze
                    </MuiLink>
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#fff" }}>
                    <MuiLink href="/workshop" color="inherit" underline="none">
                      Workshop
                    </MuiLink>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Right Column: Updates */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ marginBottom: 2, color: "#fff" }}>
                Updates
              </Typography>
              {postMapping
                .slice(-5)
                .reverse()
                .map((post, index) => (
                  <Box key={index}>
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      <MuiLink href={post.link} color="inherit" underline="none">
                        &gt; {post.title}
                      </MuiLink>
                    </Typography>
                    {index < 4 && <Divider sx={{ backgroundColor: "#fff", marginY: 1 }} />}
                  </Box>
                ))}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Fully separated white section */}
      <Box sx={{ backgroundColor: "#fff", color: "#000", paddingY: 3 }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center", // Align content vertically centered
            }}
          >
            {/* Left: Copyright Text */}
            <Typography variant="body2" color="inherit">
              @2024 Datafan
            </Typography>

            {/* Right: Legal Links */}
            <Box sx={{ display: "flex", gap: 3 }}>
              <MuiLink
                href={brochurePDF}
                color="inherit"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                Algemene voorwaarden
              </MuiLink>
              <MuiLink href="/sitemap" color="inherit" underline="none">
                Sitemap
              </MuiLink>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
