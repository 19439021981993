import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// images
import datafanImage from "../../../assets/images/datafan/2023-12-Logo-Datafan.png";

//import custom scripts
import Authenticate from "./authenticate.js";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://datafan.nl/">
        Datafan.nl
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#32aca2",
    },
  },
});

export default function Login() {
  const [loading, setLoading] = React.useState(false); // State for loading status
  const [loginStatus, setLoginStatus] = React.useState(null); // State for login status
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // Get email and password from form
    const email = data.get("email");
    const password = data.get("password");

    setLoading(true); // Set loading to true when request starts

    try {
      const authenticationRequestStatus = await Authenticate.perform_login(email, password);
      setLoginStatus(authenticationRequestStatus);
      if (!authenticationRequestStatus) {
        // Show "login failed" message
        console.log("Login failed");
      } else {
        location.reload();
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px", // Add margin at the bottom for spacing
            marginTop: "5vh",
          }}
        >
          <img src={datafanImage} alt="Datafan Image" style={{ width: "20%" }} />
          <Box
            sx={{
              width: "15%",
              borderBottom: "2px solid #32aca2",
              my: 2,
              borderRadius: "15%",
            }}
          />
          <Avatar
            sx={{
              m: 1,
              bgcolor: "#32aca2",
              marginTop: "0px",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Inloggen
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              width: "40%",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Adres"
              name="email"
              autoComplete="email"
              autoFocus
              sx={{
                "& label.Mui-focused": { color: "#32aca2" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": { borderColor: "#32aca2" },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Wachtwoord"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              sx={{
                "& label.Mui-focused": { color: "#32aca2" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": { borderColor: "#32aca2" },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {loginStatus === false && (
              <Typography color="error" align="center" sx={{ marginY: 1, fontWeight: "bold" }}>
                <WarningIcon sx={{ verticalAlign: "bottom", marginRight: 1 }} />
                Inloggen mislukt. Dubbelcheck de logingegevens.
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ backgroundColor: "#32aca2", mt: 3, mb: 2, color: "white" }}
              disabled={loading} // Disable the button when loading
            >
              {loading ? "Laden..." : "Inloggen"} {/* Display "Laden..." when loading */}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href="/authentication/reset-password-request"
                  variant="body2"
                  sx={{ color: "#32aca2" }}
                >
                  Wachtwoord vergeten?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
